import { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  ClickAwayListener,
  Divider,
  InputAdornment,
  List,
  ListItemButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  OutlinedInput,
  Paper,
  Popper,
  Stack,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// third-party
import PerfectScrollbar from "react-perfect-scrollbar";

// project imports
import MainCard from "ui-component/cards/MainCard";
import Transitions from "ui-component/extended/Transitions";
import { StyledFab } from "ui-component/button/StyledFab";

// icons
import { IconPlus, IconSearch, IconMinus } from "@tabler/icons";

// const
import { baseURL } from "store/constant";

// ==============================|| ADD NODES||============================== //

const AddNodes = ({ nodesData, node }) => {
  const theme = useTheme();
  const customization = useSelector((state) => state.customization);
  let nodeData = [
    {
      label: "Models Information",
      name: "Models Information",
      type: "Models Information",
      category: "Models",
      icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/agents/AutoGPT/autogpt.png",
      description:
        "Autonomous agent with chain of thoughts for self-guided task completion",
      baseClasses: ["Models","Solutions"],
      inputs: [
        {
          label: "Datasets",
          name: "Datasets",
          type: "Datasets",
        //   list: true,
        },
        {
          label: "Please provide your Models name",
          name: "Please provide your Models name",
          type: "string",
          //   default: 1000,
          // "optional": true
        },
        {
          label: "Select the industries applicable to you",
          name: "Select the industries applicable to you",
          type: "options",
          options: [
            {
              label: "Retail",
              name: "Retail",
            },
            {
              label: "Consumer Goods",
              name: "Consumer Goods",
            },
            {
              label: "Banking, Finance and Insurance",
              name: "Banking, Finance and Insurance",
            },
            {
              label: "Manufacturing & Logistics",
              name: "Manufacturing & Logistics",
            },
            {
              label: "Engergy & Utilities",
              name: "Engergy & Utilities",
            },
            {
              label: "Healthcare",
              name: "Healthcare",
            },
            {
              label: "LifeScience",
              name: "LifeScience",
            },
            {
              label: "Travel & Hospitality",
              name: "Travel & Hospitality",
            },
          ],
          default: "Retail",
        },
        {
          label: "What business problem would you like to resolve?*",
          name: "What business problem would you like to resolve?*",
          type: "options",
          options: [
            {
              label: "Q&A",
              name: "Q&A",
            },
            {
              label: "Text Summarization",
              name: "Text Summarization",
            },
            {
              label: "Generative AI for APIs",
              name: "Generative AI for APIs",
            },
            {
              label: "Integrate with your database",
              name: "Integrate with your database",
            },
            {
              label: "Chatbots",
              name: "Chatbots",
            },
            {
              label: "Search",
              name: "Search",
            },
            {
              label: "Image generation",
              name: "Image generation",
            },
          ],
          default: "Q&A",
        },
        {
          label: "Select the cloud platforms for hosting your services",
          name: "Select the cloud platforms for hosting your services",
          type: "options",
          options: [
            {
              label: "AWS",
              name: "AWS",
            },
            {
              label: "GCP",
              name: "GCP",
            },
            {
              label: "Azure",
              name: "Azure",
            },
            {
              label: "Private Cloud",
              name: "Private Cloud",
            },
            {
              label: "Scale",
              name: "Scale",
            },
          ],
          default: "AWS",
        },
        // {
        //   label: "Chunk Size",
        //   name: "chunkSize",
        //   type: "number",
        //   default: 1000,
        //   // "optional": true
        // },
        // {
        //   label: "company name",
        //   name: "chunkOverlap",
        //   type: "number",
        //   // "optional": true
        // },
      ],
      // "inputs": [
      //     {
      //         "label": "Allowed Tools",
      //         "name": "tools",
      //         "type": "Tool",
      //         "list": true
      //     },
      //     {
      //         "label": "Chat Model",
      //         "name": "model",
      //         "type": "BaseChatModel"
      //     },
      //     {
      //         "label": "Vector Store Retriever",
      //         "name": "vectorStoreRetriever",
      //         "type": "BaseRetriever"
      //     },
      //     {
      //         "label": "AutoGPT Name",
      //         "name": "aiName",
      //         "type": "string",
      //         "placeholder": "Tom",
      //         "optional": true
      //     },
      //     {
      //         "label": "AutoGPT Role",
      //         "name": "aiRole",
      //         "type": "string",
      //         "placeholder": "Assistant",
      //         "optional": true
      //     },
      //     {
      //         "label": "Maximum Loop",
      //         "name": "maxLoop",
      //         "type": "number",
      //         "default": 5,
      //         "optional": true
      //     }
      // ],
      filePath:
        "/usr/src/packages/node_modules/flowise-components/dist/nodes/agents/AutoGPT/AutoGPT.js",
    },
    {
      label: "Models Business Details",
      name: "Models Business Details",
      type: "Models Business Details",
      category: "Models",
      icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/agents/AutoGPT/autogpt.png",
      description:
        "Autonomous agent with chain of thoughts for self-guided task completion",
      baseClasses: ["AutoGPT"],
      inputs: [
        {
          label: "Allowed Tools",
          name: "tools",
          type: "Tool",
          list: true,
        },
        {
          label: "Chat Model",
          name: "model",
          type: "BaseChatModel",
        },
        {
          label: "Vector Store Retriever",
          name: "vectorStoreRetriever",
          type: "BaseRetriever",
        },
        {
          label: "AutoGPT Name",
          name: "aiName",
          type: "string",
          placeholder: "Tom",
          optional: true,
        },
        {
          label: "AutoGPT Role",
          name: "aiRole",
          type: "string",
          placeholder: "Assistant",
          optional: true,
        },
        {
          label: "Maximum Loop",
          name: "maxLoop",
          type: "number",
          default: 5,
          optional: true,
        },
      ],
      filePath:
        "/usr/src/packages/node_modules/flowise-components/dist/nodes/agents/AutoGPT/AutoGPT.js",
    },

    {
      label: "Datasets Information",
      name: "Datasets Information",
      type: "Datasets Information",
      category: "Datasets",
      icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/agents/AutoGPT/autogpt.png",
      description:
        "Autonomous agent with chain of thoughts for self-guided task completion",
      baseClasses: ["Datasets"],
      inputs: [
        // {
        //   label: "Allowed Tools",
        // //   name: "tools",
        // //   type: "Tool",
        // //   list: true,
        // },
        {
          label: "Please provide your company name",
          name: "Please provide your company name",
          type: "string",
          //   default: 1000,
          // "optional": true
        },
        {
          label: "Select the industries applicable to you",
          name: "Select the industries applicable to you",
          type: "options",
          options: [
            {
              label: "Retail",
              name: "Retail",
            },
            {
              label: "Consumer Goods",
              name: "Consumer Goods",
            },
            {
              label: "Banking, Finance and Insurance",
              name: "Banking, Finance and Insurance",
            },
            {
              label: "Manufacturing & Logistics",
              name: "Manufacturing & Logistics",
            },
            {
              label: "Engergy & Utilities",
              name: "Engergy & Utilities",
            },
            {
              label: "Healthcare",
              name: "Healthcare",
            },
            {
              label: "LifeScience",
              name: "LifeScience",
            },
            {
              label: "Travel & Hospitality",
              name: "Travel & Hospitality",
            },
          ],
          default: "Retail",
        },
        {
          label: "What business problem would you like to resolve?*",
          name: "What business problem would you like to resolve?*",
          type: "options",
          options: [
            {
              label: "Q&A",
              name: "Q&A",
            },
            {
              label: "Text Summarization",
              name: "Text Summarization",
            },
            {
              label: "Generative AI for APIs",
              name: "Generative AI for APIs",
            },
            {
              label: "Integrate with your database",
              name: "Integrate with your database",
            },
            {
              label: "Chatbots",
              name: "Chatbots",
            },
            {
              label: "Search",
              name: "Search",
            },
            {
              label: "Image generation",
              name: "Image generation",
            },
          ],
          default: "Q&A",
        },
        {
          label: "Select the cloud platforms for hosting your services",
          name: "Select the cloud platforms for hosting your services",
          type: "options",
          options: [
            {
              label: "AWS",
              name: "AWS",
            },
            {
              label: "GCP",
              name: "GCP",
            },
            {
              label: "Azure",
              name: "Azure",
            },
            {
              label: "Private Cloud",
              name: "Private Cloud",
            },
            {
              label: "Scale",
              name: "Scale",
            },
          ],
          default: "AWS",
        },
        // {
        //   label: "Chunk Size",
        //   name: "chunkSize",
        //   type: "number",
        //   default: 1000,
        //   // "optional": true
        // },
        // {
        //   label: "company name",
        //   name: "chunkOverlap",
        //   type: "number",
        //   // "optional": true
        // },
      ],
      // "inputs": [
      //     {
      //         "label": "Allowed Tools",
      //         "name": "tools",
      //         "type": "Tool",
      //         "list": true
      //     },
      //     {
      //         "label": "Chat Model",
      //         "name": "model",
      //         "type": "BaseChatModel"
      //     },
      //     {
      //         "label": "Vector Store Retriever",
      //         "name": "vectorStoreRetriever",
      //         "type": "BaseRetriever"
      //     },
      //     {
      //         "label": "AutoGPT Name",
      //         "name": "aiName",
      //         "type": "string",
      //         "placeholder": "Tom",
      //         "optional": true
      //     },
      //     {
      //         "label": "AutoGPT Role",
      //         "name": "aiRole",
      //         "type": "string",
      //         "placeholder": "Assistant",
      //         "optional": true
      //     },
      //     {
      //         "label": "Maximum Loop",
      //         "name": "maxLoop",
      //         "type": "number",
      //         "default": 5,
      //         "optional": true
      //     }
      // ],
      filePath:
        "/usr/src/packages/node_modules/flowise-components/dist/nodes/agents/AutoGPT/AutoGPT.js",
    },
    {
      label: "Datasets Business Details",
      name: "Datasets Business Details",
      type: "Datasets Business Details",
      category: "Datasets",
      icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/agents/AutoGPT/autogpt.png",
      description:
        "Autonomous agent with chain of thoughts for self-guided task completion",
      baseClasses: ["AutoGPT"],
      inputs: [
        {
          label: "Allowed Tools",
          name: "tools",
          type: "Tool",
          list: true,
        },
        {
          label: "Chat Model",
          name: "model",
          type: "BaseChatModel",
        },
        {
          label: "Vector Store Retriever",
          name: "vectorStoreRetriever",
          type: "BaseRetriever",
        },
        {
          label: "AutoGPT Name",
          name: "aiName",
          type: "string",
          placeholder: "Tom",
          optional: true,
        },
        {
          label: "AutoGPT Role",
          name: "aiRole",
          type: "string",
          placeholder: "Assistant",
          optional: true,
        },
        {
          label: "Maximum Loop",
          name: "maxLoop",
          type: "number",
          default: 5,
          optional: true,
        },
      ],
      filePath:
        "/usr/src/packages/node_modules/flowise-components/dist/nodes/agents/AutoGPT/AutoGPT.js",
    },

    {
      label: "Solutions Information",
      name: "Solutions Information",
      type: "Solutions Information",
      category: "Solutions",
      icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/agents/AutoGPT/autogpt.png",
      description:
        "Autonomous agent with chain of thoughts for self-guided task completion",
      baseClasses: ["Industry"],
      inputs: [
        // {
        //   label: "Allowed Tools",
        // //   name: "tools",
        // //   type: "Tool",
        // //   list: true,
        // },
        {
          label: "Please provide your company name",
          name: "Please provide your company name",
          type: "string",
          //   default: 1000,
          // "optional": true
        },
        {
          label: "Select the industries applicable to you",
          name: "Select the industries applicable to you",
          type: "options",
          options: [
            {
              label: "Retail",
              name: "Retail",
            },
            {
              label: "Consumer Goods",
              name: "Consumer Goods",
            },
            {
              label: "Banking, Finance and Insurance",
              name: "Banking, Finance and Insurance",
            },
            {
              label: "Manufacturing & Logistics",
              name: "Manufacturing & Logistics",
            },
            {
              label: "Engergy & Utilities",
              name: "Engergy & Utilities",
            },
            {
              label: "Healthcare",
              name: "Healthcare",
            },
            {
              label: "LifeScience",
              name: "LifeScience",
            },
            {
              label: "Travel & Hospitality",
              name: "Travel & Hospitality",
            },
          ],
          default: "Retail",
        },
        {
          label: "What business problem would you like to resolve?*",
          name: "What business problem would you like to resolve?*",
          type: "options",
          options: [
            {
              label: "Q&A",
              name: "Q&A",
            },
            {
              label: "Text Summarization",
              name: "Text Summarization",
            },
            {
              label: "Generative AI for APIs",
              name: "Generative AI for APIs",
            },
            {
              label: "Integrate with your database",
              name: "Integrate with your database",
            },
            {
              label: "Chatbots",
              name: "Chatbots",
            },
            {
              label: "Search",
              name: "Search",
            },
            {
              label: "Image generation",
              name: "Image generation",
            },
          ],
          default: "Q&A",
        },
        {
          label: "Select the cloud platforms for hosting your services",
          name: "Select the cloud platforms for hosting your services",
          type: "options",
          options: [
            {
              label: "AWS",
              name: "AWS",
            },
            {
              label: "GCP",
              name: "GCP",
            },
            {
              label: "Azure",
              name: "Azure",
            },
            {
              label: "Private Cloud",
              name: "Private Cloud",
            },
            {
              label: "Scale",
              name: "Scale",
            },
          ],
          default: "AWS",
        },
        // {
        //   label: "Chunk Size",
        //   name: "chunkSize",
        //   type: "number",
        //   default: 1000,
        //   // "optional": true
        // },
        // {
        //   label: "company name",
        //   name: "chunkOverlap",
        //   type: "number",
        //   // "optional": true
        // },
      ],
      // "inputs": [
      //     {
      //         "label": "Allowed Tools",
      //         "name": "tools",
      //         "type": "Tool",
      //         "list": true
      //     },
      //     {
      //         "label": "Chat Model",
      //         "name": "model",
      //         "type": "BaseChatModel"
      //     },
      //     {
      //         "label": "Vector Store Retriever",
      //         "name": "vectorStoreRetriever",
      //         "type": "BaseRetriever"
      //     },
      //     {
      //         "label": "AutoGPT Name",
      //         "name": "aiName",
      //         "type": "string",
      //         "placeholder": "Tom",
      //         "optional": true
      //     },
      //     {
      //         "label": "AutoGPT Role",
      //         "name": "aiRole",
      //         "type": "string",
      //         "placeholder": "Assistant",
      //         "optional": true
      //     },
      //     {
      //         "label": "Maximum Loop",
      //         "name": "maxLoop",
      //         "type": "number",
      //         "default": 5,
      //         "optional": true
      //     }
      // ],
      filePath:
        "/usr/src/packages/node_modules/flowise-components/dist/nodes/agents/AutoGPT/AutoGPT.js",
    },
    {
      label: "Solutions Business Details",
      name: "Solutions Business Details",
      type: "Solutions Business Details",
      category: "Solutions",
      icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/agents/AutoGPT/autogpt.png",
      description:
        "Autonomous agent with chain of thoughts for self-guided task completion",
      baseClasses: ["AutoGPT"],
      inputs: [
        {
          label: "Allowed Tools",
          name: "tools",
          type: "Tool",
          list: true,
        },
        {
          label: "Chat Model",
          name: "model",
          type: "BaseChatModel",
        },
        {
          label: "Vector Store Retriever",
          name: "vectorStoreRetriever",
          type: "BaseRetriever",
        },
        {
          label: "AutoGPT Name",
          name: "aiName",
          type: "string",
          placeholder: "Tom",
          optional: true,
        },
        {
          label: "AutoGPT Role",
          name: "aiRole",
          type: "string",
          placeholder: "Assistant",
          optional: true,
        },
        {
          label: "Maximum Loop",
          name: "maxLoop",
          type: "number",
          default: 5,
          optional: true,
        },
      ],
      filePath:
        "/usr/src/packages/node_modules/flowise-components/dist/nodes/agents/AutoGPT/AutoGPT.js",
    },
    // {
    //   label: "AutoGPT",
    //   name: "autoGPT",
    //   type: "AutoGPT",
    //   category: "Agents",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/agents/AutoGPT/autogpt.png",
    //   description:
    //     "Autonomous agent with chain of thoughts for self-guided task completion",
    //   baseClasses: ["AutoGPT"],
    //   inputs: [
    //     {
    //       label: "Allowed Tools",
    //       name: "tools",
    //       type: "Tool",
    //       list: true,
    //     },
    //     {
    //       label: "Chat Model",
    //       name: "model",
    //       type: "BaseChatModel",
    //     },
    //     {
    //       label: "Vector Store Retriever",
    //       name: "vectorStoreRetriever",
    //       type: "BaseRetriever",
    //     },
    //     {
    //       label: "AutoGPT Name",
    //       name: "aiName",
    //       type: "string",
    //       placeholder: "Tom",
    //       optional: true,
    //     },
    //     {
    //       label: "AutoGPT Role",
    //       name: "aiRole",
    //       type: "string",
    //       placeholder: "Assistant",
    //       optional: true,
    //     },
    //     {
    //       label: "Maximum Loop",
    //       name: "maxLoop",
    //       type: "number",
    //       default: 5,
    //       optional: true,
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/agents/AutoGPT/AutoGPT.js",
    // },
    // {
    //   label: "BabyAGI",
    //   name: "babyAGI",
    //   type: "BabyAGI",
    //   category: "Agents",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/agents/BabyAGI/babyagi.jpg",
    //   description:
    //     "Task Driven Autonomous Agent which creates new task and reprioritizes task list based on objective",
    //   baseClasses: ["BabyAGI"],
    //   inputs: [
    //     {
    //       label: "Chat Model",
    //       name: "model",
    //       type: "BaseChatModel",
    //     },
    //     {
    //       label: "Vector Store",
    //       name: "vectorStore",
    //       type: "VectorStore",
    //     },
    //     {
    //       label: "Task Loop",
    //       name: "taskLoop",
    //       type: "number",
    //       default: 3,
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/agents/BabyAGI/BabyAGI.js",
    // },
    // {
    //   label: "Conversational Agent",
    //   name: "conversationalAgent",
    //   type: "AgentExecutor",
    //   category: "Agents",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/agents/ConversationalAgent/agent.svg",
    //   description:
    //     "Conversational agent for a chat model. It will utilize chat specific prompts",
    //   baseClasses: ["AgentExecutor", "BaseChain"],
    //   inputs: [
    //     {
    //       label: "Allowed Tools",
    //       name: "tools",
    //       type: "Tool",
    //       list: true,
    //     },
    //     {
    //       label: "Language Model",
    //       name: "model",
    //       type: "BaseLanguageModel",
    //     },
    //     {
    //       label: "Memory",
    //       name: "memory",
    //       type: "BaseChatMemory",
    //     },
    //     {
    //       label: "System Message",
    //       name: "systemMessage",
    //       type: "string",
    //       rows: 4,
    //       optional: true,
    //       additionalParams: true,
    //     },
    //     {
    //       label: "Human Message",
    //       name: "humanMessage",
    //       type: "string",
    //       rows: 4,
    //       optional: true,
    //       additionalParams: true,
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/agents/ConversationalAgent/ConversationalAgent.js",
    // },
    // {
    //   label: "MRKL Agent for Chat Models",
    //   name: "mrklAgentChat",
    //   type: "AgentExecutor",
    //   category: "Agents",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/agents/MRKLAgentChat/agent.svg",
    //   description:
    //     "Agent that uses the ReAct Framework to decide what action to take, optimized to be used with Chat Models",
    //   baseClasses: ["AgentExecutor", "BaseChain"],
    //   inputs: [
    //     {
    //       label: "Allowed Tools",
    //       name: "tools",
    //       type: "Tool",
    //       list: true,
    //     },
    //     {
    //       label: "Language Model",
    //       name: "model",
    //       type: "BaseLanguageModel",
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/agents/MRKLAgentChat/MRKLAgentChat.js",
    // },
    // {
    //   label: "MRKL Agent for LLMs",
    //   name: "mrklAgentLLM",
    //   type: "AgentExecutor",
    //   category: "Agents",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/agents/MRKLAgentLLM/agent.svg",
    //   description:
    //     "Agent that uses the ReAct Framework to decide what action to take, optimized to be used with LLMs",
    //   baseClasses: ["AgentExecutor", "BaseChain"],
    //   inputs: [
    //     {
    //       label: "Allowed Tools",
    //       name: "tools",
    //       type: "Tool",
    //       list: true,
    //     },
    //     {
    //       label: "Language Model",
    //       name: "model",
    //       type: "BaseLanguageModel",
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/agents/MRKLAgentLLM/MRKLAgentLLM.js",
    // },
    // {
    //   label: "OpenAI Function Agent",
    //   name: "openAIFunctionAgent",
    //   type: "AgentExecutor",
    //   category: "Agents",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/agents/OpenAIFunctionAgent/openai.png",
    //   description:
    //     "An agent that uses OpenAI's Function Calling functionality to pick the tool and args to call",
    //   baseClasses: ["AgentExecutor", "BaseChain"],
    //   inputs: [
    //     {
    //       label: "Allowed Tools",
    //       name: "tools",
    //       type: "Tool",
    //       list: true,
    //     },
    //     {
    //       label: "Memory",
    //       name: "memory",
    //       type: "BaseChatMemory",
    //     },
    //     {
    //       label: "OpenAI Chat Model",
    //       name: "model",
    //       description:
    //         'Only works with gpt-3.5-turbo-0613 and gpt-4-0613. Refer <a target="_blank" href="https://platform.openai.com/docs/guides/gpt/function-calling">docs</a> for more info',
    //       type: "BaseChatModel",
    //     },
    //     {
    //       label: "System Message",
    //       name: "systemMessage",
    //       type: "string",
    //       rows: 4,
    //       optional: true,
    //       additionalParams: true,
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/agents/OpenAIFunctionAgent/OpenAIFunctionAgent.js",
    // },
    // {
    //   label: "GET API Chain",
    //   name: "getApiChain",
    //   type: "GETApiChain",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/chains/ApiChain/apichain.svg",
    //   category: "Chains",
    //   description: "Chain to run queries against GET API",
    //   baseClasses: ["GETApiChain", "BaseChain"],
    //   inputs: [
    //     {
    //       label: "Language Model",
    //       name: "model",
    //       type: "BaseLanguageModel",
    //     },
    //     {
    //       label: "API Documentation",
    //       name: "apiDocs",
    //       type: "string",
    //       description:
    //         'Description of how API works. Please refer to more <a target="_blank" href="https://github.com/hwchase17/langchain/blob/master/langchain/chains/api/open_meteo_docs.py">examples</a>',
    //       rows: 4,
    //     },
    //     {
    //       label: "Headers",
    //       name: "headers",
    //       type: "json",
    //       additionalParams: true,
    //       optional: true,
    //     },
    //     {
    //       label: "URL Prompt",
    //       name: "urlPrompt",
    //       type: "string",
    //       description:
    //         "Prompt used to tell LLMs how to construct the URL. Must contains {api_docs} and {question}",
    //       default:
    //         "You are given the below API Documentation:\n{api_docs}\nUsing this documentation, generate the full API url to call for answering the user question.\nYou should build the API url in order to get a response that is as short as possible, while still getting the necessary information to answer the question. Pay attention to deliberately exclude any unnecessary pieces of data in the API call.\n\nQuestion:{question}\nAPI url:",
    //       rows: 4,
    //       additionalParams: true,
    //     },
    //     {
    //       label: "Answer Prompt",
    //       name: "ansPrompt",
    //       type: "string",
    //       description:
    //         "Prompt used to tell LLMs how to return the API response. Must contains {api_response}, {api_url}, and {question}",
    //       default:
    //         "Given this {api_response} response for {api_url}. use the given response to answer this {question}",
    //       rows: 4,
    //       additionalParams: true,
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/chains/ApiChain/GETApiChain.js",
    // },
    // {
    //   label: "OpenAPI Chain",
    //   name: "openApiChain",
    //   type: "openApiChain",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/chains/ApiChain/openapi.png",
    //   category: "Chains",
    //   description: "Chain to run queries against OpenAPI",
    //   baseClasses: ["openApiChain", "BaseChain"],
    //   inputs: [
    //     {
    //       label: "ChatOpenAI Model",
    //       name: "model",
    //       type: "ChatOpenAI",
    //     },
    //     {
    //       label: "YAML Link",
    //       name: "yamlLink",
    //       type: "string",
    //       placeholder: "https://api.speak.com/openapi.yaml",
    //       description:
    //         "If YAML link is provided, uploaded YAML File will be ignored and YAML link will be used instead",
    //     },
    //     {
    //       label: "YAML File",
    //       name: "yamlFile",
    //       type: "file",
    //       fileType: ".yaml",
    //       description:
    //         "If YAML link is provided, uploaded YAML File will be ignored and YAML link will be used instead",
    //     },
    //     {
    //       label: "Headers",
    //       name: "headers",
    //       type: "json",
    //       additionalParams: true,
    //       optional: true,
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/chains/ApiChain/OpenAPIChain.js",
    // },
    // {
    //   label: "POST API Chain",
    //   name: "postApiChain",
    //   type: "POSTApiChain",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/chains/ApiChain/apichain.svg",
    //   category: "Chains",
    //   description: "Chain to run queries against POST API",
    //   baseClasses: ["POSTApiChain", "BaseChain"],
    //   inputs: [
    //     {
    //       label: "Language Model",
    //       name: "model",
    //       type: "BaseLanguageModel",
    //     },
    //     {
    //       label: "API Documentation",
    //       name: "apiDocs",
    //       type: "string",
    //       description:
    //         'Description of how API works. Please refer to more <a target="_blank" href="https://github.com/hwchase17/langchain/blob/master/langchain/chains/api/open_meteo_docs.py">examples</a>',
    //       rows: 4,
    //     },
    //     {
    //       label: "Headers",
    //       name: "headers",
    //       type: "json",
    //       additionalParams: true,
    //       optional: true,
    //     },
    //     {
    //       label: "URL Prompt",
    //       name: "urlPrompt",
    //       type: "string",
    //       description:
    //         "Prompt used to tell LLMs how to construct the URL. Must contains {api_docs} and {question}",
    //       default:
    //         'You are given the below API Documentation:\n{api_docs}\nUsing this documentation, generate a json string with two keys: "url" and "data".\nThe value of "url" should be a string, which is the API url to call for answering the user question.\nThe value of "data" should be a dictionary of key-value pairs you want to POST to the url as a JSON body.\nBe careful to always use double quotes for strings in the json string.\nYou should build the json string in order to get a response that is as short as possible, while still getting the necessary information to answer the question. Pay attention to deliberately exclude any unnecessary pieces of data in the API call.\n\nQuestion:{question}\njson string:',
    //       rows: 4,
    //       additionalParams: true,
    //     },
    //     {
    //       label: "Answer Prompt",
    //       name: "ansPrompt",
    //       type: "string",
    //       description:
    //         "Prompt used to tell LLMs how to return the API response. Must contains {api_response}, {api_url}, and {question}",
    //       default:
    //         'You are given the below API Documentation:\n{api_docs}\nUsing this documentation, generate a json string with two keys: "url" and "data".\nThe value of "url" should be a string, which is the API url to call for answering the user question.\nThe value of "data" should be a dictionary of key-value pairs you want to POST to the url as a JSON body.\nBe careful to always use double quotes for strings in the json string.\nYou should build the json string in order to get a response that is as short as possible, while still getting the necessary information to answer the question. Pay attention to deliberately exclude any unnecessary pieces of data in the API call.\n\nQuestion:{question}\njson string: {api_url_body}\n\nHere is the response from the API:\n\n{api_response}\n\nSummarize this response to answer the original question.\n\nSummary:',
    //       rows: 4,
    //       additionalParams: true,
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/chains/ApiChain/POSTApiChain.js",
    // },
    // {
    //   label: "Conversation Chain",
    //   name: "conversationChain",
    //   type: "ConversationChain",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/chains/ConversationChain/chain.svg",
    //   category: "Chains",
    //   description: "Chat models specific conversational chain with memory",
    //   baseClasses: ["ConversationChain", "LLMChain", "BaseChain"],
    //   inputs: [
    //     {
    //       label: "Language Model",
    //       name: "model",
    //       type: "BaseChatModel",
    //     },
    //     {
    //       label: "Memory",
    //       name: "memory",
    //       type: "BaseMemory",
    //     },
    //     {
    //       label: "System Message",
    //       name: "systemMessagePrompt",
    //       type: "string",
    //       rows: 4,
    //       additionalParams: true,
    //       optional: true,
    //       placeholder: "You are a helpful assistant that write codes",
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/chains/ConversationChain/ConversationChain.js",
    // },
    // {
    //   label: "Conversational Retrieval QA Chain",
    //   name: "conversationalRetrievalQAChain",
    //   type: "ConversationalRetrievalQAChain",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/chains/ConversationalRetrievalQAChain/chain.svg",
    //   category: "Chains",
    //   description:
    //     "Document QA - built on RetrievalQAChain to provide a chat history component",
    //   baseClasses: ["ConversationalRetrievalQAChain", "BaseChain"],
    //   inputs: [
    //     {
    //       label: "Language Model",
    //       name: "model",
    //       type: "BaseLanguageModel",
    //     },
    //     {
    //       label: "Vector Store Retriever",
    //       name: "vectorStoreRetriever",
    //       type: "BaseRetriever",
    //     },
    //     {
    //       label: "Memory",
    //       name: "memory",
    //       type: "DynamoDBChatMemory | RedisBackedChatMemory | ZepMemory",
    //       optional: true,
    //       description:
    //         "If no memory connected, default BufferMemory will be used",
    //     },
    //     {
    //       label: "Return Source Documents",
    //       name: "returnSourceDocuments",
    //       type: "boolean",
    //       optional: true,
    //     },
    //     {
    //       label: "System Message",
    //       name: "systemMessagePrompt",
    //       type: "string",
    //       rows: 4,
    //       additionalParams: true,
    //       optional: true,
    //       placeholder:
    //         'I want you to act as a document that I am having a conversation with. Your name is "AI Assistant". You will provide me with answers from the given info. If the answer is not included, say exactly "Hmm, I am not sure." and stop after that. Refuse to answer any question not about the info. Never break character.',
    //     },
    //     {
    //       label: "Chain Option",
    //       name: "chainOption",
    //       type: "options",
    //       options: [
    //         {
    //           label: "MapReduceDocumentsChain",
    //           name: "map_reduce",
    //           description:
    //             "Suitable for QA tasks over larger documents and can run the preprocessing step in parallel, reducing the running time",
    //         },
    //         {
    //           label: "RefineDocumentsChain",
    //           name: "refine",
    //           description:
    //             "Suitable for QA tasks over a large number of documents.",
    //         },
    //         {
    //           label: "StuffDocumentsChain",
    //           name: "stuff",
    //           description:
    //             "Suitable for QA tasks over a small number of documents.",
    //         },
    //       ],
    //       additionalParams: true,
    //       optional: true,
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/chains/ConversationalRetrievalQAChain/ConversationalRetrievalQAChain.js",
    // },
    // {
    //   label: "LLM Chain",
    //   name: "llmChain",
    //   type: "LLMChain",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/chains/LLMChain/chain.svg",
    //   category: "Chains",
    //   description: "Chain to run queries against LLMs",
    //   baseClasses: ["LLMChain", "BaseChain"],
    //   inputs: [
    //     {
    //       label: "Language Model",
    //       name: "model",
    //       type: "BaseLanguageModel",
    //     },
    //     {
    //       label: "Prompt",
    //       name: "prompt",
    //       type: "BasePromptTemplate",
    //     },
    //     {
    //       label: "Chain Name",
    //       name: "chainName",
    //       type: "string",
    //       placeholder: "Name Your Chain",
    //       optional: true,
    //     },
    //   ],
    //   outputs: [
    //     {
    //       label: "LLM Chain",
    //       name: "llmChain",
    //       baseClasses: ["LLMChain", "BaseChain"],
    //     },
    //     {
    //       label: "Output Prediction",
    //       name: "outputPrediction",
    //       baseClasses: ["string", "json"],
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/chains/LLMChain/LLMChain.js",
    // },
    // {
    //   label: "Multi Prompt Chain",
    //   name: "multiPromptChain",
    //   type: "MultiPromptChain",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/chains/MultiPromptChain/chain.svg",
    //   category: "Chains",
    //   description:
    //     "Chain automatically picks an appropriate prompt from multiple prompt templates",
    //   baseClasses: ["MultiPromptChain", "MultiRouteChain", "BaseChain"],
    //   inputs: [
    //     {
    //       label: "Language Model",
    //       name: "model",
    //       type: "BaseLanguageModel",
    //     },
    //     {
    //       label: "Prompt Retriever",
    //       name: "promptRetriever",
    //       type: "PromptRetriever",
    //       list: true,
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/chains/MultiPromptChain/MultiPromptChain.js",
    // },
    // {
    //   label: "Multi Retrieval QA Chain",
    //   name: "multiRetrievalQAChain",
    //   type: "MultiRetrievalQAChain",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/chains/MultiRetrievalQAChain/chain.svg",
    //   category: "Chains",
    //   description:
    //     "QA Chain that automatically picks an appropriate vector store from multiple retrievers",
    //   baseClasses: ["MultiRetrievalQAChain", "MultiRouteChain", "BaseChain"],
    //   inputs: [
    //     {
    //       label: "Language Model",
    //       name: "model",
    //       type: "BaseLanguageModel",
    //     },
    //     {
    //       label: "Vector Store Retriever",
    //       name: "vectorStoreRetriever",
    //       type: "VectorStoreRetriever",
    //       list: true,
    //     },
    //     {
    //       label: "Return Source Documents",
    //       name: "returnSourceDocuments",
    //       type: "boolean",
    //       optional: true,
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/chains/MultiRetrievalQAChain/MultiRetrievalQAChain.js",
    // },
    // {
    //   label: "Retrieval QA Chain",
    //   name: "retrievalQAChain",
    //   type: "RetrievalQAChain",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/chains/RetrievalQAChain/chain.svg",
    //   category: "Chains",
    //   description:
    //     "QA chain to answer a question based on the retrieved documents",
    //   baseClasses: ["RetrievalQAChain", "BaseChain"],
    //   inputs: [
    //     {
    //       label: "Language Model",
    //       name: "model",
    //       type: "BaseLanguageModel",
    //     },
    //     {
    //       label: "Vector Store Retriever",
    //       name: "vectorStoreRetriever",
    //       type: "BaseRetriever",
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/chains/RetrievalQAChain/RetrievalQAChain.js",
    // },
    // {
    //   label: "Sql Database Chain",
    //   name: "sqlDatabaseChain",
    //   type: "SqlDatabaseChain",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/chains/SqlDatabaseChain/sqlchain.svg",
    //   category: "Chains",
    //   description: "Answer questions over a SQL database",
    //   baseClasses: ["SqlDatabaseChain", "BaseChain"],
    //   inputs: [
    //     {
    //       label: "Language Model",
    //       name: "model",
    //       type: "BaseLanguageModel",
    //     },
    //     {
    //       label: "Database",
    //       name: "database",
    //       type: "options",
    //       options: [
    //         {
    //           label: "SQlite",
    //           name: "sqlite",
    //         },
    //       ],
    //       default: "sqlite",
    //     },
    //     {
    //       label: "Database File Path",
    //       name: "dbFilePath",
    //       type: "string",
    //       placeholder: "C:/Users/chinook.db",
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/chains/SqlDatabaseChain/SqlDatabaseChain.js",
    // },
    // {
    //   label: "VectorDB QA Chain",
    //   name: "vectorDBQAChain",
    //   type: "VectorDBQAChain",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/chains/VectorDBQAChain/chain.svg",
    //   category: "Chains",
    //   description: "QA chain for vector databases",
    //   baseClasses: ["VectorDBQAChain", "BaseChain"],
    //   inputs: [
    //     {
    //       label: "Language Model",
    //       name: "model",
    //       type: "BaseLanguageModel",
    //     },
    //     {
    //       label: "Vector Store",
    //       name: "vectorStore",
    //       type: "VectorStore",
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/chains/VectorDBQAChain/VectorDBQAChain.js",
    // },
    // {
    //   label: "Azure ChatOpenAI",
    //   name: "azureChatOpenAI",
    //   type: "AzureChatOpenAI",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/chatmodels/AzureChatOpenAI/Azure.svg",
    //   category: "Chat Models",
    //   description:
    //     "Wrapper around Azure OpenAI large language models that use the Chat endpoint",
    //   baseClasses: ["AzureChatOpenAI", "BaseChatModel", "BaseLanguageModel"],
    //   inputs: [
    //     {
    //       label: "Azure OpenAI Api Key",
    //       name: "azureOpenAIApiKey",
    //       type: "password",
    //     },
    //     {
    //       label: "Model Name",
    //       name: "modelName",
    //       type: "options",
    //       options: [
    //         {
    //           label: "gpt-4",
    //           name: "gpt-4",
    //         },
    //         {
    //           label: "gpt-4-32k",
    //           name: "gpt-4-32k",
    //         },
    //         {
    //           label: "gpt-35-turbo",
    //           name: "gpt-35-turbo",
    //         },
    //         {
    //           label: "gpt-35-turbo-16k",
    //           name: "gpt-35-turbo-16k",
    //         },
    //       ],
    //       default: "gpt-35-turbo",
    //       optional: true,
    //     },
    //     {
    //       label: "Temperature",
    //       name: "temperature",
    //       type: "number",
    //       default: 0.9,
    //       optional: true,
    //     },
    //     {
    //       label: "Azure OpenAI Api Instance Name",
    //       name: "azureOpenAIApiInstanceName",
    //       type: "string",
    //       placeholder: "YOUR-INSTANCE-NAME",
    //     },
    //     {
    //       label: "Azure OpenAI Api Deployment Name",
    //       name: "azureOpenAIApiDeploymentName",
    //       type: "string",
    //       placeholder: "YOUR-DEPLOYMENT-NAME",
    //     },
    //     {
    //       label: "Azure OpenAI Api Version",
    //       name: "azureOpenAIApiVersion",
    //       type: "string",
    //       placeholder: "2023-06-01-preview",
    //       description:
    //         'Description of Supported API Versions. Please refer <a target="_blank" href="https://learn.microsoft.com/en-us/azure/cognitive-services/openai/reference#chat-completions">examples</a>',
    //     },
    //     {
    //       label: "Max Tokens",
    //       name: "maxTokens",
    //       type: "number",
    //       optional: true,
    //       additionalParams: true,
    //     },
    //     {
    //       label: "Frequency Penalty",
    //       name: "frequencyPenalty",
    //       type: "number",
    //       optional: true,
    //       additionalParams: true,
    //     },
    //     {
    //       label: "Presence Penalty",
    //       name: "presencePenalty",
    //       type: "number",
    //       optional: true,
    //       additionalParams: true,
    //     },
    //     {
    //       label: "Timeout",
    //       name: "timeout",
    //       type: "number",
    //       optional: true,
    //       additionalParams: true,
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/chatmodels/AzureChatOpenAI/AzureChatOpenAI.js",
    // },
    // {
    //   label: "ChatAnthropic",
    //   name: "chatAnthropic",
    //   type: "ChatAnthropic",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/chatmodels/ChatAnthropic/chatAnthropic.png",
    //   category: "Chat Models",
    //   description:
    //     "Wrapper around ChatAnthropic large language models that use the Chat endpoint",
    //   baseClasses: ["ChatAnthropic", "BaseChatModel", "BaseLanguageModel"],
    //   inputs: [
    //     {
    //       label: "ChatAnthropic Api Key",
    //       name: "anthropicApiKey",
    //       type: "password",
    //     },
    //     {
    //       label: "Model Name",
    //       name: "modelName",
    //       type: "options",
    //       options: [
    //         {
    //           label: "claude-v1",
    //           name: "claude-v1",
    //         },
    //         {
    //           label: "claude-v1-100k",
    //           name: "claude-v1-100k",
    //         },
    //         {
    //           label: "claude-v1.0",
    //           name: "claude-v1.0",
    //         },
    //         {
    //           label: "claude-v1.2",
    //           name: "claude-v1.2",
    //         },
    //         {
    //           label: "claude-v1.3",
    //           name: "claude-v1.3",
    //         },
    //         {
    //           label: "claude-v1.3-100k",
    //           name: "claude-v1.3-100k",
    //         },
    //         {
    //           label: "claude-instant-v1",
    //           name: "claude-instant-v1",
    //         },
    //         {
    //           label: "claude-instant-v1-100k",
    //           name: "claude-instant-v1-100k",
    //         },
    //         {
    //           label: "claude-instant-v1.0",
    //           name: "claude-instant-v1.0",
    //         },
    //         {
    //           label: "claude-instant-v1.1",
    //           name: "claude-instant-v1.1",
    //         },
    //         {
    //           label: "claude-instant-v1.1-100k",
    //           name: "claude-instant-v1.1-100k",
    //         },
    //       ],
    //       default: "claude-v1",
    //       optional: true,
    //     },
    //     {
    //       label: "Temperature",
    //       name: "temperature",
    //       type: "number",
    //       default: 0.9,
    //       optional: true,
    //     },
    //     {
    //       label: "Max Tokens",
    //       name: "maxTokensToSample",
    //       type: "number",
    //       optional: true,
    //       additionalParams: true,
    //     },
    //     {
    //       label: "Top P",
    //       name: "topP",
    //       type: "number",
    //       optional: true,
    //       additionalParams: true,
    //     },
    //     {
    //       label: "Top K",
    //       name: "topK",
    //       type: "number",
    //       optional: true,
    //       additionalParams: true,
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/chatmodels/ChatAnthropic/ChatAnthropic.js",
    // },
    // {
    //   label: "ChatHuggingFace",
    //   name: "chatHuggingFace",
    //   type: "ChatHuggingFace",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/chatmodels/ChatHuggingFace/huggingface.png",
    //   category: "Chat Models",
    //   description: "Wrapper around HuggingFace large language models",
    //   baseClasses: [
    //     "ChatHuggingFace",
    //     "BaseChatModel",
    //     "LLM",
    //     "BaseLLM",
    //     "BaseLanguageModel",
    //   ],
    //   inputs: [
    //     {
    //       label: "Model",
    //       name: "model",
    //       type: "string",
    //       placeholder: "gpt2",
    //     },
    //     {
    //       label: "HuggingFace Api Key",
    //       name: "apiKey",
    //       type: "password",
    //     },
    //     {
    //       label: "Temperature",
    //       name: "temperature",
    //       type: "number",
    //       description:
    //         "Temperature parameter may not apply to certain model. Please check available model parameters",
    //       optional: true,
    //       additionalParams: true,
    //     },
    //     {
    //       label: "Max Tokens",
    //       name: "maxTokens",
    //       type: "number",
    //       description:
    //         "Max Tokens parameter may not apply to certain model. Please check available model parameters",
    //       optional: true,
    //       additionalParams: true,
    //     },
    //     {
    //       label: "Top Probability",
    //       name: "topP",
    //       type: "number",
    //       description:
    //         "Top Probability parameter may not apply to certain model. Please check available model parameters",
    //       optional: true,
    //       additionalParams: true,
    //     },
    //     {
    //       label: "Top K",
    //       name: "hfTopK",
    //       type: "number",
    //       description:
    //         "Top K parameter may not apply to certain model. Please check available model parameters",
    //       optional: true,
    //       additionalParams: true,
    //     },
    //     {
    //       label: "Frequency Penalty",
    //       name: "frequencyPenalty",
    //       type: "number",
    //       description:
    //         "Frequency Penalty parameter may not apply to certain model. Please check available model parameters",
    //       optional: true,
    //       additionalParams: true,
    //     },
    //     {
    //       label: "Endpoint",
    //       name: "endpoint",
    //       type: "string",
    //       placeholder:
    //         "https://xyz.eu-west-1.aws.endpoints.huggingface.cloud/gpt2",
    //       description: "Using your own inference endpoint",
    //       optional: true,
    //       additionalParams: true,
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/chatmodels/ChatHuggingFace/ChatHuggingFace.js",
    // },
    // {
    //   label: "ChatLocalAI",
    //   name: "chatLocalAI",
    //   type: "ChatLocalAI",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/chatmodels/ChatLocalAI/localai.png",
    //   category: "Chat Models",
    //   description: "Use local LLMs like llama.cpp, gpt4all using LocalAI",
    //   baseClasses: [
    //     "ChatLocalAI",
    //     "BaseChatModel",
    //     "LLM",
    //     "BaseLLM",
    //     "BaseLanguageModel",
    //   ],
    //   inputs: [
    //     {
    //       label: "Base Path",
    //       name: "basePath",
    //       type: "string",
    //       placeholder: "http://localhost:8080/v1",
    //     },
    //     {
    //       label: "Model Name",
    //       name: "modelName",
    //       type: "string",
    //       placeholder: "gpt4all-lora-quantized.bin",
    //     },
    //     {
    //       label: "Temperature",
    //       name: "temperature",
    //       type: "number",
    //       default: 0.9,
    //       optional: true,
    //     },
    //     {
    //       label: "Max Tokens",
    //       name: "maxTokens",
    //       type: "number",
    //       optional: true,
    //       additionalParams: true,
    //     },
    //     {
    //       label: "Top Probability",
    //       name: "topP",
    //       type: "number",
    //       optional: true,
    //       additionalParams: true,
    //     },
    //     {
    //       label: "Timeout",
    //       name: "timeout",
    //       type: "number",
    //       optional: true,
    //       additionalParams: true,
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/chatmodels/ChatLocalAI/ChatLocalAI.js",
    // },
    // {
    //   label: "ChatOpenAI",
    //   name: "chatOpenAI",
    //   type: "ChatOpenAI",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/chatmodels/ChatOpenAI/openai.png",
    //   category: "Chat Models",
    //   description:
    //     "Wrapper around OpenAI large language models that use the Chat endpoint",
    //   baseClasses: ["ChatOpenAI", "BaseChatModel", "BaseLanguageModel"],
    //   inputs: [
    //     {
    //       label: "OpenAI Api Key",
    //       name: "openAIApiKey",
    //       type: "password",
    //     },
    //     {
    //       label: "Model Name",
    //       name: "modelName",
    //       type: "options",
    //       options: [
    //         {
    //           label: "gpt-4",
    //           name: "gpt-4",
    //         },
    //         {
    //           label: "gpt-4-0613",
    //           name: "gpt-4-0613",
    //         },
    //         {
    //           label: "gpt-4-32k",
    //           name: "gpt-4-32k",
    //         },
    //         {
    //           label: "gpt-4-32k-0613",
    //           name: "gpt-4-32k-0613",
    //         },
    //         {
    //           label: "gpt-3.5-turbo",
    //           name: "gpt-3.5-turbo",
    //         },
    //         {
    //           label: "gpt-3.5-turbo-0613",
    //           name: "gpt-3.5-turbo-0613",
    //         },
    //         {
    //           label: "gpt-3.5-turbo-16k",
    //           name: "gpt-3.5-turbo-16k",
    //         },
    //         {
    //           label: "gpt-3.5-turbo-16k-0613",
    //           name: "gpt-3.5-turbo-16k-0613",
    //         },
    //       ],
    //       default: "gpt-3.5-turbo",
    //       optional: true,
    //     },
    //     {
    //       label: "Temperature",
    //       name: "temperature",
    //       type: "number",
    //       default: 0.9,
    //       optional: true,
    //     },
    //     {
    //       label: "Max Tokens",
    //       name: "maxTokens",
    //       type: "number",
    //       optional: true,
    //       additionalParams: true,
    //     },
    //     {
    //       label: "Top Probability",
    //       name: "topP",
    //       type: "number",
    //       optional: true,
    //       additionalParams: true,
    //     },
    //     {
    //       label: "Frequency Penalty",
    //       name: "frequencyPenalty",
    //       type: "number",
    //       optional: true,
    //       additionalParams: true,
    //     },
    //     {
    //       label: "Presence Penalty",
    //       name: "presencePenalty",
    //       type: "number",
    //       optional: true,
    //       additionalParams: true,
    //     },
    //     {
    //       label: "Timeout",
    //       name: "timeout",
    //       type: "number",
    //       optional: true,
    //       additionalParams: true,
    //     },
    //     {
    //       label: "BasePath",
    //       name: "basepath",
    //       type: "string",
    //       optional: true,
    //       additionalParams: true,
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/chatmodels/ChatOpenAI/ChatOpenAI.js",
    // },
    // {
    //   label: "Cheerio Web Scraper",
    //   name: "cheerioWebScraper",
    //   type: "Document",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/documentloaders/Cheerio/cheerio.svg",
    //   category: "Document Loaders",
    //   description: "Load data from webpages",
    //   baseClasses: ["Document"],
    //   inputs: [
    //     {
    //       label: "URL",
    //       name: "url",
    //       type: "string",
    //     },
    //     {
    //       label: "Text Splitter",
    //       name: "textSplitter",
    //       type: "TextSplitter",
    //       optional: true,
    //     },
    //     {
    //       label: "Get Relative Links Method",
    //       name: "relativeLinksMethod",
    //       type: "options",
    //       description: "Select a method to retrieve relative links",
    //       options: [
    //         {
    //           label: "Web Crawl",
    //           name: "webCrawl",
    //           description: "Crawl relative links from HTML URL",
    //         },
    //         {
    //           label: "Scrape XML Sitemap",
    //           name: "scrapeXMLSitemap",
    //           description: "Scrape relative links from XML sitemap URL",
    //         },
    //       ],
    //       optional: true,
    //       additionalParams: true,
    //     },
    //     {
    //       label: "Get Relative Links Limit",
    //       name: "limit",
    //       type: "number",
    //       optional: true,
    //       additionalParams: true,
    //       description:
    //         'Only used when "Get Relative Links Method" is selected. Set 0 to retrieve all relative links, default limit is 10.',
    //       warning:
    //         "Retreiving all links might take long time, and all links will be upserted again if the flow's state changed (eg: different URL, chunk size, etc)",
    //     },
    //     {
    //       label: "Metadata",
    //       name: "metadata",
    //       type: "json",
    //       optional: true,
    //       additionalParams: true,
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/documentloaders/Cheerio/Cheerio.js",
    // },
    // {
    //   label: "Confluence",
    //   name: "confluence",
    //   type: "Document",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/documentloaders/Confluence/confluence.png",
    //   category: "Document Loaders",
    //   description: "Load data from a Confluence Document",
    //   baseClasses: ["Document"],
    //   inputs: [
    //     {
    //       label: "Text Splitter",
    //       name: "textSplitter",
    //       type: "TextSplitter",
    //       optional: true,
    //     },
    //     {
    //       label: "Username",
    //       name: "username",
    //       type: "string",
    //       placeholder: "<CONFLUENCE_USERNAME>",
    //     },
    //     {
    //       label: "Access Token",
    //       name: "accessToken",
    //       type: "password",
    //       placeholder: "<CONFLUENCE_ACCESS_TOKEN>",
    //     },
    //     {
    //       label: "Base URL",
    //       name: "baseUrl",
    //       type: "string",
    //       placeholder: "https://example.atlassian.net/wiki",
    //     },
    //     {
    //       label: "Space Key",
    //       name: "spaceKey",
    //       type: "string",
    //       placeholder: "~EXAMPLE362906de5d343d49dcdbae5dEXAMPLE",
    //     },
    //     {
    //       label: "Limit",
    //       name: "limit",
    //       type: "number",
    //       default: 0,
    //       optional: true,
    //     },
    //     {
    //       label: "Metadata",
    //       name: "metadata",
    //       type: "json",
    //       optional: true,
    //       additionalParams: true,
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/documentloaders/Confluence/Confluence.js",
    // },
    // {
    //   label: "Csv File",
    //   name: "csvFile",
    //   type: "Document",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/documentloaders/Csv/Csv.png",
    //   category: "Document Loaders",
    //   description: "Load data from CSV files",
    //   baseClasses: ["Document"],
    //   inputs: [
    //     {
    //       label: "Csv File",
    //       name: "csvFile",
    //       type: "file",
    //       fileType: ".csv",
    //     },
    //     {
    //       label: "Text Splitter",
    //       name: "textSplitter",
    //       type: "TextSplitter",
    //       optional: true,
    //     },
    //     {
    //       label: "Single Column Extraction",
    //       name: "columnName",
    //       type: "string",
    //       description: "Extracting a single column",
    //       placeholder: "Enter column name",
    //       optional: true,
    //     },
    //     {
    //       label: "Metadata",
    //       name: "metadata",
    //       type: "json",
    //       optional: true,
    //       additionalParams: true,
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/documentloaders/Csv/Csv.js",
    // },
    // {
    //   label: "Docx File",
    //   name: "docxFile",
    //   type: "Document",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/documentloaders/Docx/Docx.png",
    //   category: "Document Loaders",
    //   description: "Load data from DOCX files",
    //   baseClasses: ["Document"],
    //   inputs: [
    //     {
    //       label: "Docx File",
    //       name: "docxFile",
    //       type: "file",
    //       fileType: ".docx",
    //     },
    //     {
    //       label: "Text Splitter",
    //       name: "textSplitter",
    //       type: "TextSplitter",
    //       optional: true,
    //     },
    //     {
    //       label: "Metadata",
    //       name: "metadata",
    //       type: "json",
    //       optional: true,
    //       additionalParams: true,
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/documentloaders/Docx/Docx.js",
    // },
    // {
    //   label: "Figma",
    //   name: "figma",
    //   type: "Document",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/documentloaders/Figma/figma.png",
    //   category: "Document Loaders",
    //   description: "Load data from a Figma file",
    //   baseClasses: ["Document"],
    //   inputs: [
    //     {
    //       label: "Access Token",
    //       name: "accessToken",
    //       type: "password",
    //       placeholder: "<FIGMA_ACCESS_TOKEN>",
    //     },
    //     {
    //       label: "File Key",
    //       name: "fileKey",
    //       type: "string",
    //       placeholder: "key",
    //     },
    //     {
    //       label: "Node IDs",
    //       name: "nodeIds",
    //       type: "string",
    //       placeholder: "0, 1, 2",
    //     },
    //     {
    //       label: "Recursive",
    //       name: "recursive",
    //       type: "boolean",
    //       optional: true,
    //     },
    //     {
    //       label: "Text Splitter",
    //       name: "textSplitter",
    //       type: "TextSplitter",
    //       optional: true,
    //     },
    //     {
    //       label: "Metadata",
    //       name: "metadata",
    //       type: "json",
    //       optional: true,
    //       additionalParams: true,
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/documentloaders/Figma/Figma.js",
    // },
    // {
    //   label: "Folder with Files",
    //   name: "folderFiles",
    //   type: "Document",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/documentloaders/Folder/folder.svg",
    //   category: "Document Loaders",
    //   description: "Load data from folder with multiple files",
    //   baseClasses: ["Document"],
    //   inputs: [
    //     {
    //       label: "Folder Path",
    //       name: "folderPath",
    //       type: "string",
    //       placeholder: "",
    //     },
    //     {
    //       label: "Text Splitter",
    //       name: "textSplitter",
    //       type: "TextSplitter",
    //       optional: true,
    //     },
    //     {
    //       label: "Metadata",
    //       name: "metadata",
    //       type: "json",
    //       optional: true,
    //       additionalParams: true,
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/documentloaders/Folder/Folder.js",
    // },
    // {
    //   label: "GitBook",
    //   name: "gitbook",
    //   type: "Document",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/documentloaders/Gitbook/gitbook.svg",
    //   category: "Document Loaders",
    //   description: "Load data from GitBook",
    //   baseClasses: ["Document"],
    //   inputs: [
    //     {
    //       label: "Web Path",
    //       name: "webPath",
    //       type: "string",
    //       placeholder: "https://docs.gitbook.com/product-tour/navigation",
    //       description:
    //         "If want to load all paths from the GitBook provide only root path e.g.https://docs.gitbook.com/ ",
    //     },
    //     {
    //       label: "Should Load All Paths",
    //       name: "shouldLoadAllPaths",
    //       type: "boolean",
    //       description: "Load from all paths in a given GitBook",
    //       optional: true,
    //     },
    //     {
    //       label: "Text Splitter",
    //       name: "textSplitter",
    //       type: "TextSplitter",
    //       optional: true,
    //     },
    //     {
    //       label: "Metadata",
    //       name: "metadata",
    //       type: "json",
    //       optional: true,
    //       additionalParams: true,
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/documentloaders/Gitbook/Gitbook.js",
    // },
    // {
    //   label: "Github",
    //   name: "github",
    //   type: "Document",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/documentloaders/Github/github.png",
    //   category: "Document Loaders",
    //   description: "Load data from a GitHub repository",
    //   baseClasses: ["Document"],
    //   inputs: [
    //     {
    //       label: "Repo Link",
    //       name: "repoLink",
    //       type: "string",
    //       placeholder: "https://github.com/FlowiseAI/Flowise",
    //     },
    //     {
    //       label: "Branch",
    //       name: "branch",
    //       type: "string",
    //       default: "main",
    //     },
    //     {
    //       label: "Access Token",
    //       name: "accessToken",
    //       type: "password",
    //       placeholder: "<GITHUB_ACCESS_TOKEN>",
    //       optional: true,
    //     },
    //     {
    //       label: "Recursive",
    //       name: "recursive",
    //       type: "boolean",
    //       optional: true,
    //     },
    //     {
    //       label: "Text Splitter",
    //       name: "textSplitter",
    //       type: "TextSplitter",
    //       optional: true,
    //     },
    //     {
    //       label: "Metadata",
    //       name: "metadata",
    //       type: "json",
    //       optional: true,
    //       additionalParams: true,
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/documentloaders/Github/Github.js",
    // },
    // {
    //   label: "Json File",
    //   name: "jsonFile",
    //   type: "Document",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/documentloaders/Json/json.svg",
    //   category: "Document Loaders",
    //   description: "Load data from JSON files",
    //   baseClasses: ["Document"],
    //   inputs: [
    //     {
    //       label: "Json File",
    //       name: "jsonFile",
    //       type: "file",
    //       fileType: ".json",
    //     },
    //     {
    //       label: "Text Splitter",
    //       name: "textSplitter",
    //       type: "TextSplitter",
    //       optional: true,
    //     },
    //     {
    //       label: "Pointers Extraction (separated by commas)",
    //       name: "pointersName",
    //       type: "string",
    //       description: "Extracting multiple pointers",
    //       placeholder: "Enter pointers name",
    //       optional: true,
    //     },
    //     {
    //       label: "Metadata",
    //       name: "metadata",
    //       type: "json",
    //       optional: true,
    //       additionalParams: true,
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/documentloaders/Json/Json.js",
    // },
    // {
    //   label: "Json Lines File",
    //   name: "jsonlinesFile",
    //   type: "Document",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/documentloaders/Jsonlines/jsonlines.svg",
    //   category: "Document Loaders",
    //   description: "Load data from JSON Lines files",
    //   baseClasses: ["Document"],
    //   inputs: [
    //     {
    //       label: "Jsonlines File",
    //       name: "jsonlinesFile",
    //       type: "file",
    //       fileType: ".jsonl",
    //     },
    //     {
    //       label: "Text Splitter",
    //       name: "textSplitter",
    //       type: "TextSplitter",
    //       optional: true,
    //     },
    //     {
    //       label: "Pointer Extraction",
    //       name: "pointerName",
    //       type: "string",
    //       placeholder: "Enter pointer name",
    //       optional: false,
    //     },
    //     {
    //       label: "Metadata",
    //       name: "metadata",
    //       type: "json",
    //       optional: true,
    //       additionalParams: true,
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/documentloaders/Jsonlines/Jsonlines.js",
    // },
    // {
    //   label: "Notion Database",
    //   name: "notionDB",
    //   type: "Document",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/documentloaders/NotionDB/notion.png",
    //   category: "Document Loaders",
    //   description: "Load data from Notion Database ID",
    //   baseClasses: ["Document"],
    //   inputs: [
    //     {
    //       label: "Text Splitter",
    //       name: "textSplitter",
    //       type: "TextSplitter",
    //       optional: true,
    //     },
    //     {
    //       label: "Notion Database Id",
    //       name: "databaseId",
    //       type: "string",
    //       description:
    //         "If your URL looks like - https://www.notion.so/<long_hash_1>?v=<long_hash_2>, then <long_hash_1> is the database ID",
    //     },
    //     {
    //       label: "Notion Integration Token",
    //       name: "notionIntegrationToken",
    //       type: "password",
    //       description:
    //         'You can find integration token <a target="_blank" href="https://developers.notion.com/docs/create-a-notion-integration#step-1-create-an-integration">here</a>',
    //     },
    //     {
    //       label: "Page Size Limit",
    //       name: "pageSizeLimit",
    //       type: "number",
    //       default: 10,
    //     },
    //     {
    //       label: "Metadata",
    //       name: "metadata",
    //       type: "json",
    //       optional: true,
    //       additionalParams: true,
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/documentloaders/NotionDB/NotionDB.js",
    // },
    // {
    //   label: "Notion Folder",
    //   name: "notionFolder",
    //   type: "Document",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/documentloaders/NotionFolder/notion.png",
    //   category: "Document Loaders",
    //   description: "Load data from the exported and unzipped Notion folder",
    //   baseClasses: ["Document"],
    //   inputs: [
    //     {
    //       label: "Notion Folder",
    //       name: "notionFolder",
    //       type: "string",
    //       description: "Get folder path",
    //       placeholder: "Paste folder path",
    //     },
    //     {
    //       label: "Text Splitter",
    //       name: "textSplitter",
    //       type: "TextSplitter",
    //       optional: true,
    //     },
    //     {
    //       label: "Metadata",
    //       name: "metadata",
    //       type: "json",
    //       optional: true,
    //       additionalParams: true,
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/documentloaders/NotionFolder/NotionFolder.js",
    // },
    // {
    //   label: "Pdf File",
    //   name: "pdfFile",
    //   type: "Document",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/documentloaders/Pdf/pdf.svg",
    //   category: "Document Loaders",
    //   description: "Load data from PDF files",
    //   baseClasses: ["Document"],
    //   inputs: [
    //     {
    //       label: "Pdf File",
    //       name: "pdfFile",
    //       type: "file",
    //       fileType: ".pdf",
    //     },
    //     {
    //       label: "Text Splitter",
    //       name: "textSplitter",
    //       type: "TextSplitter",
    //       optional: true,
    //     },
    //     {
    //       label: "Usage",
    //       name: "usage",
    //       type: "options",
    //       options: [
    //         {
    //           label: "One document per page",
    //           name: "perPage",
    //         },
    //         {
    //           label: "One document per file",
    //           name: "perFile",
    //         },
    //       ],
    //       default: "perPage",
    //     },
    //     {
    //       label: "Use Legacy Build",
    //       name: "legacyBuild",
    //       type: "boolean",
    //       optional: true,
    //       additionalParams: true,
    //     },
    //     {
    //       label: "Metadata",
    //       name: "metadata",
    //       type: "json",
    //       optional: true,
    //       additionalParams: true,
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/documentloaders/Pdf/Pdf.js",
    // },
    // {
    //   label: "Playwright Web Scraper",
    //   name: "playwrightWebScraper",
    //   type: "Document",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/documentloaders/Playwright/playwright.svg",
    //   category: "Document Loaders",
    //   description: "Load data from webpages",
    //   baseClasses: ["Document"],
    //   inputs: [
    //     {
    //       label: "URL",
    //       name: "url",
    //       type: "string",
    //     },
    //     {
    //       label: "Text Splitter",
    //       name: "textSplitter",
    //       type: "TextSplitter",
    //       optional: true,
    //     },
    //     {
    //       label: "Get Relative Links Method",
    //       name: "relativeLinksMethod",
    //       type: "options",
    //       description: "Select a method to retrieve relative links",
    //       options: [
    //         {
    //           label: "Web Crawl",
    //           name: "webCrawl",
    //           description: "Crawl relative links from HTML URL",
    //         },
    //         {
    //           label: "Scrape XML Sitemap",
    //           name: "scrapeXMLSitemap",
    //           description: "Scrape relative links from XML sitemap URL",
    //         },
    //       ],
    //       optional: true,
    //       additionalParams: true,
    //     },
    //     {
    //       label: "Get Relative Links Limit",
    //       name: "limit",
    //       type: "number",
    //       optional: true,
    //       additionalParams: true,
    //       description:
    //         'Only used when "Get Relative Links Method" is selected. Set 0 to retrieve all relative links, default limit is 10.',
    //       warning:
    //         "Retreiving all links might take long time, and all links will be upserted again if the flow's state changed (eg: different URL, chunk size, etc)",
    //     },
    //     {
    //       label: "Metadata",
    //       name: "metadata",
    //       type: "json",
    //       optional: true,
    //       additionalParams: true,
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/documentloaders/Playwright/Playwright.js",
    // },
    // {
    //   label: "Puppeteer Web Scraper",
    //   name: "puppeteerWebScraper",
    //   type: "Document",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/documentloaders/Puppeteer/puppeteer.svg",
    //   category: "Document Loaders",
    //   description: "Load data from webpages",
    //   baseClasses: ["Document"],
    //   inputs: [
    //     {
    //       label: "URL",
    //       name: "url",
    //       type: "string",
    //     },
    //     {
    //       label: "Text Splitter",
    //       name: "textSplitter",
    //       type: "TextSplitter",
    //       optional: true,
    //     },
    //     {
    //       label: "Get Relative Links Method",
    //       name: "relativeLinksMethod",
    //       type: "options",
    //       description: "Select a method to retrieve relative links",
    //       options: [
    //         {
    //           label: "Web Crawl",
    //           name: "webCrawl",
    //           description: "Crawl relative links from HTML URL",
    //         },
    //         {
    //           label: "Scrape XML Sitemap",
    //           name: "scrapeXMLSitemap",
    //           description: "Scrape relative links from XML sitemap URL",
    //         },
    //       ],
    //       optional: true,
    //       additionalParams: true,
    //     },
    //     {
    //       label: "Get Relative Links Limit",
    //       name: "limit",
    //       type: "number",
    //       optional: true,
    //       additionalParams: true,
    //       description:
    //         'Only used when "Get Relative Links Method" is selected. Set 0 to retrieve all relative links, default limit is 10.',
    //       warning:
    //         "Retreiving all links might take long time, and all links will be upserted again if the flow's state changed (eg: different URL, chunk size, etc)",
    //     },
    //     {
    //       label: "Metadata",
    //       name: "metadata",
    //       type: "json",
    //       optional: true,
    //       additionalParams: true,
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/documentloaders/Puppeteer/Puppeteer.js",
    // },
    // {
    //   label: "Subtitles File",
    //   name: "subtitlesFile",
    //   type: "Document",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/documentloaders/Subtitles/subtitlesFile.svg",
    //   category: "Document Loaders",
    //   description: "Load data from subtitles files",
    //   baseClasses: ["Document"],
    //   inputs: [
    //     {
    //       label: "Subtitles File",
    //       name: "subtitlesFile",
    //       type: "file",
    //       fileType: ".srt",
    //     },
    //     {
    //       label: "Text Splitter",
    //       name: "textSplitter",
    //       type: "TextSplitter",
    //       optional: true,
    //     },
    //     {
    //       label: "Metadata",
    //       name: "metadata",
    //       type: "json",
    //       optional: true,
    //       additionalParams: true,
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/documentloaders/Subtitles/Subtitles.js",
    // },
    // {
    //   label: "Text File",
    //   name: "textFile",
    //   type: "Document",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/documentloaders/Text/textFile.svg",
    //   category: "Document Loaders",
    //   description: "Load data from text files",
    //   baseClasses: ["Document"],
    //   inputs: [
    //     {
    //       label: "Txt File",
    //       name: "txtFile",
    //       type: "file",
    //       fileType: ".txt",
    //     },
    //     {
    //       label: "Text Splitter",
    //       name: "textSplitter",
    //       type: "TextSplitter",
    //       optional: true,
    //     },
    //     {
    //       label: "Metadata",
    //       name: "metadata",
    //       type: "json",
    //       optional: true,
    //       additionalParams: true,
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/documentloaders/Text/Text.js",
    // },
    // {
    //   label: "Azure OpenAI Embeddings",
    //   name: "azureOpenAIEmbeddings",
    //   type: "AzureOpenAIEmbeddings",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/embeddings/AzureOpenAIEmbedding/Azure.svg",
    //   category: "Embeddings",
    //   description: "Azure OpenAI API to generate embeddings for a given text",
    //   baseClasses: ["AzureOpenAIEmbeddings", "Embeddings"],
    //   inputs: [
    //     {
    //       label: "Azure OpenAI Api Key",
    //       name: "azureOpenAIApiKey",
    //       type: "password",
    //     },
    //     {
    //       label: "Azure OpenAI Api Instance Name",
    //       name: "azureOpenAIApiInstanceName",
    //       type: "string",
    //       placeholder: "YOUR-INSTANCE-NAME",
    //     },
    //     {
    //       label: "Azure OpenAI Api Deployment Name",
    //       name: "azureOpenAIApiDeploymentName",
    //       type: "string",
    //       placeholder: "YOUR-DEPLOYMENT-NAME",
    //     },
    //     {
    //       label: "Azure OpenAI Api Version",
    //       name: "azureOpenAIApiVersion",
    //       type: "string",
    //       placeholder: "2023-03-15-preview",
    //       description:
    //         'Description of Supported API Versions. Please refer <a target="_blank" href="https://learn.microsoft.com/en-us/azure/cognitive-services/openai/reference#embeddings">examples</a>',
    //     },
    //     {
    //       label: "Batch Size",
    //       name: "batchSize",
    //       type: "number",
    //       default: "1",
    //       optional: true,
    //       additionalParams: true,
    //     },
    //     {
    //       label: "Timeout",
    //       name: "timeout",
    //       type: "number",
    //       optional: true,
    //       additionalParams: true,
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/embeddings/AzureOpenAIEmbedding/AzureOpenAIEmbedding.js",
    // },
    // {
    //   label: "Cohere Embeddings",
    //   name: "cohereEmbeddings",
    //   type: "CohereEmbeddings",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/embeddings/CohereEmbedding/cohere.png",
    //   category: "Embeddings",
    //   description: "Cohere API to generate embeddings for a given text",
    //   baseClasses: ["CohereEmbeddings", "Embeddings"],
    //   inputs: [
    //     {
    //       label: "Cohere API Key",
    //       name: "cohereApiKey",
    //       type: "password",
    //     },
    //     {
    //       label: "Model Name",
    //       name: "modelName",
    //       type: "options",
    //       options: [
    //         {
    //           label: "embed-english-v2.0",
    //           name: "embed-english-v2.0",
    //         },
    //         {
    //           label: "embed-english-light-v2.0",
    //           name: "embed-english-light-v2.0",
    //         },
    //         {
    //           label: "embed-multilingual-v2.0",
    //           name: "embed-multilingual-v2.0",
    //         },
    //       ],
    //       default: "embed-english-v2.0",
    //       optional: true,
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/embeddings/CohereEmbedding/CohereEmbedding.js",
    // },
    // {
    //   label: "HuggingFace Inference Embeddings",
    //   name: "huggingFaceInferenceEmbeddings",
    //   type: "HuggingFaceInferenceEmbeddings",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/embeddings/HuggingFaceInferenceEmbedding/huggingface.png",
    //   category: "Embeddings",
    //   description:
    //     "HuggingFace Inference API to generate embeddings for a given text",
    //   baseClasses: ["HuggingFaceInferenceEmbeddings", "Embeddings"],
    //   inputs: [
    //     {
    //       label: "HuggingFace Api Key",
    //       name: "apiKey",
    //       type: "password",
    //     },
    //     {
    //       label: "Model",
    //       name: "modelName",
    //       type: "string",
    //       optional: true,
    //     },
    //     {
    //       label: "Endpoint",
    //       name: "endpoint",
    //       type: "string",
    //       placeholder:
    //         "https://xyz.eu-west-1.aws.endpoints.huggingface.cloud/sentence-transformers/all-MiniLM-L6-v2",
    //       description: "Using your own inference endpoint",
    //       optional: true,
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/embeddings/HuggingFaceInferenceEmbedding/HuggingFaceInferenceEmbedding.js",
    // },
    // {
    //   label: "LocalAI Embeddings",
    //   name: "localAIEmbeddings",
    //   type: "LocalAI Embeddings",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/embeddings/LocalAIEmbedding/localai.png",
    //   category: "Embeddings",
    //   description: "Use local embeddings models like llama.cpp",
    //   baseClasses: ["LocalAI Embeddings", "Embeddings"],
    //   inputs: [
    //     {
    //       label: "Base Path",
    //       name: "basePath",
    //       type: "string",
    //       placeholder: "http://localhost:8080/v1",
    //     },
    //     {
    //       label: "Model Name",
    //       name: "modelName",
    //       type: "string",
    //       placeholder: "text-embedding-ada-002",
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/embeddings/LocalAIEmbedding/LocalAIEmbedding.js",
    // },
    // {
    //   label: "OpenAI Embeddings",
    //   name: "openAIEmbeddings",
    //   type: "OpenAIEmbeddings",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/embeddings/OpenAIEmbedding/openai.png",
    //   category: "Embeddings",
    //   description: "OpenAI API to generate embeddings for a given text",
    //   baseClasses: ["OpenAIEmbeddings", "Embeddings"],
    //   inputs: [
    //     {
    //       label: "OpenAI Api Key",
    //       name: "openAIApiKey",
    //       type: "password",
    //     },
    //     {
    //       label: "Strip New Lines",
    //       name: "stripNewLines",
    //       type: "boolean",
    //       optional: true,
    //       additionalParams: true,
    //     },
    //     {
    //       label: "Batch Size",
    //       name: "batchSize",
    //       type: "number",
    //       optional: true,
    //       additionalParams: true,
    //     },
    //     {
    //       label: "Timeout",
    //       name: "timeout",
    //       type: "number",
    //       optional: true,
    //       additionalParams: true,
    //     },
    //     {
    //       label: "BasePath",
    //       name: "basepath",
    //       type: "string",
    //       optional: true,
    //       additionalParams: true,
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/embeddings/OpenAIEmbedding/OpenAIEmbedding.js",
    // },
    // {
    //   label: "Azure OpenAI",
    //   name: "azureOpenAI",
    //   type: "AzureOpenAI",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/llms/Azure OpenAI/Azure.svg",
    //   category: "LLMs",
    //   description: "Wrapper around Azure OpenAI large language models",
    //   baseClasses: ["AzureOpenAI", "BaseLLM", "BaseLanguageModel"],
    //   inputs: [
    //     {
    //       label: "Azure OpenAI Api Key",
    //       name: "azureOpenAIApiKey",
    //       type: "password",
    //     },
    //     {
    //       label: "Model Name",
    //       name: "modelName",
    //       type: "options",
    //       options: [
    //         {
    //           label: "text-davinci-003",
    //           name: "text-davinci-003",
    //         },
    //         {
    //           label: "ada",
    //           name: "ada",
    //         },
    //         {
    //           label: "text-ada-001",
    //           name: "text-ada-001",
    //         },
    //         {
    //           label: "babbage",
    //           name: "babbage",
    //         },
    //         {
    //           label: "text-babbage-001",
    //           name: "text-babbage-001",
    //         },
    //         {
    //           label: "curie",
    //           name: "curie",
    //         },
    //         {
    //           label: "text-curie-001",
    //           name: "text-curie-001",
    //         },
    //         {
    //           label: "davinci",
    //           name: "davinci",
    //         },
    //         {
    //           label: "text-davinci-001",
    //           name: "text-davinci-001",
    //         },
    //         {
    //           label: "text-davinci-002",
    //           name: "text-davinci-002",
    //         },
    //         {
    //           label: "text-davinci-fine-tune-002",
    //           name: "text-davinci-fine-tune-002",
    //         },
    //         {
    //           label: "gpt-35-turbo",
    //           name: "gpt-35-turbo",
    //         },
    //       ],
    //       default: "text-davinci-003",
    //       optional: true,
    //     },
    //     {
    //       label: "Temperature",
    //       name: "temperature",
    //       type: "number",
    //       default: 0.9,
    //       optional: true,
    //     },
    //     {
    //       label: "Azure OpenAI Api Instance Name",
    //       name: "azureOpenAIApiInstanceName",
    //       type: "string",
    //       placeholder: "YOUR-INSTANCE-NAME",
    //     },
    //     {
    //       label: "Azure OpenAI Api Deployment Name",
    //       name: "azureOpenAIApiDeploymentName",
    //       type: "string",
    //       placeholder: "YOUR-DEPLOYMENT-NAME",
    //     },
    //     {
    //       label: "Azure OpenAI Api Version",
    //       name: "azureOpenAIApiVersion",
    //       type: "string",
    //       placeholder: "2023-06-01-preview",
    //       description:
    //         'Description of Supported API Versions. Please refer <a target="_blank" href="https://learn.microsoft.com/en-us/azure/cognitive-services/openai/reference#completions">examples</a>',
    //     },
    //     {
    //       label: "Max Tokens",
    //       name: "maxTokens",
    //       type: "number",
    //       optional: true,
    //       additionalParams: true,
    //     },
    //     {
    //       label: "Top Probability",
    //       name: "topP",
    //       type: "number",
    //       optional: true,
    //       additionalParams: true,
    //     },
    //     {
    //       label: "Best Of",
    //       name: "bestOf",
    //       type: "number",
    //       optional: true,
    //       additionalParams: true,
    //     },
    //     {
    //       label: "Frequency Penalty",
    //       name: "frequencyPenalty",
    //       type: "number",
    //       optional: true,
    //       additionalParams: true,
    //     },
    //     {
    //       label: "Presence Penalty",
    //       name: "presencePenalty",
    //       type: "number",
    //       optional: true,
    //       additionalParams: true,
    //     },
    //     {
    //       label: "Timeout",
    //       name: "timeout",
    //       type: "number",
    //       optional: true,
    //       additionalParams: true,
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/llms/Azure OpenAI/AzureOpenAI.js",
    // },
    // {
    //   label: "Cohere",
    //   name: "cohere",
    //   type: "Cohere",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/llms/Cohere/cohere.png",
    //   category: "LLMs",
    //   description: "Wrapper around Cohere large language models",
    //   baseClasses: ["Cohere", "LLM", "BaseLLM", "BaseLanguageModel"],
    //   inputs: [
    //     {
    //       label: "Cohere Api Key",
    //       name: "cohereApiKey",
    //       type: "password",
    //     },
    //     {
    //       label: "Model Name",
    //       name: "modelName",
    //       type: "options",
    //       options: [
    //         {
    //           label: "command",
    //           name: "command",
    //         },
    //         {
    //           label: "command-light",
    //           name: "command-light",
    //         },
    //         {
    //           label: "command-nightly",
    //           name: "command-nightly",
    //         },
    //         {
    //           label: "command-light-nightly",
    //           name: "command-light-nightly",
    //         },
    //         {
    //           label: "base",
    //           name: "base",
    //         },
    //         {
    //           label: "base-light",
    //           name: "base-light",
    //         },
    //       ],
    //       default: "command",
    //       optional: true,
    //     },
    //     {
    //       label: "Temperature",
    //       name: "temperature",
    //       type: "number",
    //       default: 0.7,
    //       optional: true,
    //     },
    //     {
    //       label: "Max Tokens",
    //       name: "maxTokens",
    //       type: "number",
    //       optional: true,
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/llms/Cohere/Cohere.js",
    // },
    // {
    //   label: "HuggingFace Inference",
    //   name: "huggingFaceInference_LLMs",
    //   type: "HuggingFaceInference",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/llms/HuggingFaceInference/huggingface.png",
    //   category: "LLMs",
    //   description: "Wrapper around HuggingFace large language models",
    //   baseClasses: [
    //     "HuggingFaceInference",
    //     "LLM",
    //     "BaseLLM",
    //     "BaseLanguageModel",
    //   ],
    //   inputs: [
    //     {
    //       label: "Model",
    //       name: "model",
    //       type: "string",
    //       placeholder: "gpt2",
    //     },
    //     {
    //       label: "HuggingFace Api Key",
    //       name: "apiKey",
    //       type: "password",
    //     },
    //     {
    //       label: "Temperature",
    //       name: "temperature",
    //       type: "number",
    //       description:
    //         "Temperature parameter may not apply to certain model. Please check available model parameters",
    //       optional: true,
    //       additionalParams: true,
    //     },
    //     {
    //       label: "Max Tokens",
    //       name: "maxTokens",
    //       type: "number",
    //       description:
    //         "Max Tokens parameter may not apply to certain model. Please check available model parameters",
    //       optional: true,
    //       additionalParams: true,
    //     },
    //     {
    //       label: "Top Probability",
    //       name: "topP",
    //       type: "number",
    //       description:
    //         "Top Probability parameter may not apply to certain model. Please check available model parameters",
    //       optional: true,
    //       additionalParams: true,
    //     },
    //     {
    //       label: "Top K",
    //       name: "hfTopK",
    //       type: "number",
    //       description:
    //         "Top K parameter may not apply to certain model. Please check available model parameters",
    //       optional: true,
    //       additionalParams: true,
    //     },
    //     {
    //       label: "Frequency Penalty",
    //       name: "frequencyPenalty",
    //       type: "number",
    //       description:
    //         "Frequency Penalty parameter may not apply to certain model. Please check available model parameters",
    //       optional: true,
    //       additionalParams: true,
    //     },
    //     {
    //       label: "Endpoint",
    //       name: "endpoint",
    //       type: "string",
    //       placeholder:
    //         "https://xyz.eu-west-1.aws.endpoints.huggingface.cloud/gpt2",
    //       description: "Using your own inference endpoint",
    //       optional: true,
    //       additionalParams: true,
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/llms/HuggingFaceInference/HuggingFaceInference.js",
    // },
    // {
    //   label: "OpenAI",
    //   name: "openAI",
    //   type: "OpenAI",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/llms/OpenAI/openai.png",
    //   category: "LLMs",
    //   description: "Wrapper around OpenAI large language models",
    //   baseClasses: ["OpenAI", "BaseLLM", "BaseLanguageModel"],
    //   inputs: [
    //     {
    //       label: "OpenAI Api Key",
    //       name: "openAIApiKey",
    //       type: "password",
    //     },
    //     {
    //       label: "Model Name",
    //       name: "modelName",
    //       type: "options",
    //       options: [
    //         {
    //           label: "text-davinci-003",
    //           name: "text-davinci-003",
    //         },
    //         {
    //           label: "text-davinci-002",
    //           name: "text-davinci-002",
    //         },
    //         {
    //           label: "text-curie-001",
    //           name: "text-curie-001",
    //         },
    //         {
    //           label: "text-babbage-001",
    //           name: "text-babbage-001",
    //         },
    //       ],
    //       default: "text-davinci-003",
    //       optional: true,
    //     },
    //     {
    //       label: "Temperature",
    //       name: "temperature",
    //       type: "number",
    //       default: 0.7,
    //       optional: true,
    //     },
    //     {
    //       label: "Max Tokens",
    //       name: "maxTokens",
    //       type: "number",
    //       optional: true,
    //       additionalParams: true,
    //     },
    //     {
    //       label: "Top Probability",
    //       name: "topP",
    //       type: "number",
    //       optional: true,
    //       additionalParams: true,
    //     },
    //     {
    //       label: "Best Of",
    //       name: "bestOf",
    //       type: "number",
    //       optional: true,
    //       additionalParams: true,
    //     },
    //     {
    //       label: "Frequency Penalty",
    //       name: "frequencyPenalty",
    //       type: "number",
    //       optional: true,
    //       additionalParams: true,
    //     },
    //     {
    //       label: "Presence Penalty",
    //       name: "presencePenalty",
    //       type: "number",
    //       optional: true,
    //       additionalParams: true,
    //     },
    //     {
    //       label: "Batch Size",
    //       name: "batchSize",
    //       type: "number",
    //       optional: true,
    //       additionalParams: true,
    //     },
    //     {
    //       label: "Timeout",
    //       name: "timeout",
    //       type: "number",
    //       optional: true,
    //       additionalParams: true,
    //     },
    //     {
    //       label: "BasePath",
    //       name: "basepath",
    //       type: "string",
    //       optional: true,
    //       additionalParams: true,
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/llms/OpenAI/OpenAI.js",
    // },
    // {
    //   label: "Buffer Memory",
    //   name: "bufferMemory",
    //   type: "BufferMemory",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/memory/BufferMemory/memory.svg",
    //   category: "Memory",
    //   description: "Remembers previous conversational back and forths directly",
    //   baseClasses: ["BufferMemory", "BaseChatMemory", "BaseMemory"],
    //   inputs: [
    //     {
    //       label: "Memory Key",
    //       name: "memoryKey",
    //       type: "string",
    //       default: "chat_history",
    //     },
    //     {
    //       label: "Input Key",
    //       name: "inputKey",
    //       type: "string",
    //       default: "input",
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/memory/BufferMemory/BufferMemory.js",
    // },
    // {
    //   label: "Buffer Window Memory",
    //   name: "bufferWindowMemory",
    //   type: "BufferWindowMemory",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/memory/BufferWindowMemory/memory.svg",
    //   category: "Memory",
    //   description:
    //     "Uses a window of size k to surface the last k back-and-forths to use as memory",
    //   baseClasses: ["BufferWindowMemory", "BaseChatMemory", "BaseMemory"],
    //   inputs: [
    //     {
    //       label: "Memory Key",
    //       name: "memoryKey",
    //       type: "string",
    //       default: "chat_history",
    //     },
    //     {
    //       label: "Input Key",
    //       name: "inputKey",
    //       type: "string",
    //       default: "input",
    //     },
    //     {
    //       label: "Size",
    //       name: "k",
    //       type: "number",
    //       default: "4",
    //       description:
    //         "Window of size k to surface the last k back-and-forths to use as memory.",
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/memory/BufferWindowMemory/BufferWindowMemory.js",
    // },
    // {
    //   label: "Conversation Summary Memory",
    //   name: "conversationSummaryMemory",
    //   type: "ConversationSummaryMemory",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/memory/ConversationSummaryMemory/memory.svg",
    //   category: "Memory",
    //   description:
    //     "Summarizes the conversation and stores the current summary in memory",
    //   baseClasses: [
    //     "ConversationSummaryMemory",
    //     "BaseChatMemory",
    //     "BaseMemory",
    //   ],
    //   inputs: [
    //     {
    //       label: "Chat Model",
    //       name: "model",
    //       type: "BaseChatModel",
    //     },
    //     {
    //       label: "Memory Key",
    //       name: "memoryKey",
    //       type: "string",
    //       default: "chat_history",
    //     },
    //     {
    //       label: "Input Key",
    //       name: "inputKey",
    //       type: "string",
    //       default: "input",
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/memory/ConversationSummaryMemory/ConversationSummaryMemory.js",
    // },
    // {
    //   label: "DynamoDB Chat Memory",
    //   name: "DynamoDBChatMemory",
    //   type: "DynamoDBChatMemory",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/memory/DynamoDb/dynamodb.svg",
    //   category: "Memory",
    //   description: "Stores the conversation in dynamo db table",
    //   baseClasses: ["DynamoDBChatMemory", "BaseChatMemory", "BaseMemory"],
    //   inputs: [
    //     {
    //       label: "Table Name",
    //       name: "tableName",
    //       type: "string",
    //     },
    //     {
    //       label: "Partition Key",
    //       name: "partitionKey",
    //       type: "string",
    //     },
    //     {
    //       label: "Session ID",
    //       name: "sessionId",
    //       type: "string",
    //       description: "if empty, chatId will be used automatically",
    //       default: "",
    //       additionalParams: true,
    //       optional: true,
    //     },
    //     {
    //       label: "Region",
    //       name: "region",
    //       type: "string",
    //       description: "The aws region in which table is located",
    //       placeholder: "us-east-1",
    //     },
    //     {
    //       label: "Access Key",
    //       name: "accessKey",
    //       type: "password",
    //     },
    //     {
    //       label: "Secret Access Key",
    //       name: "secretAccessKey",
    //       type: "password",
    //     },
    //     {
    //       label: "Memory Key",
    //       name: "memoryKey",
    //       type: "string",
    //       default: "chat_history",
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/memory/DynamoDb/DynamoDb.js",
    // },
    // {
    //   label: "Redis-Backed Chat Memory",
    //   name: "RedisBackedChatMemory",
    //   type: "RedisBackedChatMemory",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/memory/RedisBackedChatMemory/redis.svg",
    //   category: "Memory",
    //   description:
    //     "Summarizes the conversation and stores the memory in Redis server",
    //   baseClasses: ["RedisBackedChatMemory", "BaseChatMemory", "BaseMemory"],
    //   inputs: [
    //     {
    //       label: "Base URL",
    //       name: "baseURL",
    //       type: "string",
    //       default: "redis://localhost:6379",
    //     },
    //     {
    //       label: "Session Id",
    //       name: "sessionId",
    //       type: "string",
    //       description: "if empty, chatId will be used automatically",
    //       default: "",
    //       additionalParams: true,
    //       optional: true,
    //     },
    //     {
    //       label: "Session Timeouts",
    //       name: "sessionTTL",
    //       type: "number",
    //       description: "Omit this parameter to make sessions never expire",
    //       optional: true,
    //     },
    //     {
    //       label: "Memory Key",
    //       name: "memoryKey",
    //       type: "string",
    //       default: "chat_history",
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/memory/RedisBackedChatMemory/RedisBackedChatMemory.js",
    // },
    // {
    //   label: "Zep Memory",
    //   name: "ZepMemory",
    //   type: "ZepMemory",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/memory/ZepMemory/zep.png",
    //   category: "Memory",
    //   description:
    //     "Summarizes the conversation and stores the memory in zep server",
    //   baseClasses: ["ZepMemory", "BaseChatMemory", "BaseMemory"],
    //   inputs: [
    //     {
    //       label: "Base URL",
    //       name: "baseURL",
    //       type: "string",
    //       default: "http://127.0.0.1:8000",
    //     },
    //     {
    //       label: "Auto Summary",
    //       name: "autoSummary",
    //       type: "boolean",
    //       default: true,
    //     },
    //     {
    //       label: "Session Id",
    //       name: "sessionId",
    //       type: "string",
    //       description: "if empty, chatId will be used automatically",
    //       default: "",
    //       additionalParams: true,
    //       optional: true,
    //     },
    //     {
    //       label: "Auto Summary Template",
    //       name: "autoSummaryTemplate",
    //       type: "string",
    //       default:
    //         "This is the summary of the following conversation:\n{summary}",
    //       additionalParams: true,
    //     },
    //     {
    //       label: "AI Prefix",
    //       name: "aiPrefix",
    //       type: "string",
    //       default: "ai",
    //       additionalParams: true,
    //     },
    //     {
    //       label: "Human Prefix",
    //       name: "humanPrefix",
    //       type: "string",
    //       default: "human",
    //       additionalParams: true,
    //     },
    //     {
    //       label: "Memory Key",
    //       name: "memoryKey",
    //       type: "string",
    //       default: "chat_history",
    //       additionalParams: true,
    //     },
    //     {
    //       label: "Input Key",
    //       name: "inputKey",
    //       type: "string",
    //       default: "input",
    //       additionalParams: true,
    //     },
    //     {
    //       label: "Output Key",
    //       name: "outputKey",
    //       type: "string",
    //       default: "text",
    //       additionalParams: true,
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/memory/ZepMemory/ZepMemory.js",
    // },
    // {
    //   label: "Chat Prompt Template",
    //   name: "chatPromptTemplate",
    //   type: "ChatPromptTemplate",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/prompts/ChatPromptTemplate/prompt.svg",
    //   category: "Prompts",
    //   description: "Schema to represent a chat prompt",
    //   baseClasses: [
    //     "ChatPromptTemplate",
    //     "BaseChatPromptTemplate",
    //     "BasePromptTemplate",
    //   ],
    //   inputs: [
    //     {
    //       label: "System Message",
    //       name: "systemMessagePrompt",
    //       type: "string",
    //       rows: 4,
    //       placeholder:
    //         "You are a helpful assistant that translates {input_language} to {output_language}.",
    //     },
    //     {
    //       label: "Human Message",
    //       name: "humanMessagePrompt",
    //       type: "string",
    //       rows: 4,
    //       placeholder: "{text}",
    //     },
    //     {
    //       label: "Format Prompt Values",
    //       name: "promptValues",
    //       type: "json",
    //       optional: true,
    //       acceptVariable: true,
    //       list: true,
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/prompts/ChatPromptTemplate/ChatPromptTemplate.js",
    // },
    // {
    //   label: "Few Shot Prompt Template",
    //   name: "fewShotPromptTemplate",
    //   type: "FewShotPromptTemplate",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/prompts/FewShotPromptTemplate/prompt.svg",
    //   category: "Prompts",
    //   description: "Prompt template you can build with examples",
    //   baseClasses: [
    //     "FewShotPromptTemplate",
    //     "BaseStringPromptTemplate",
    //     "BasePromptTemplate",
    //   ],
    //   inputs: [
    //     {
    //       label: "Examples",
    //       name: "examples",
    //       type: "string",
    //       rows: 4,
    //       placeholder:
    //         '[\n  { "word": "happy", "antonym": "sad" },\n  { "word": "tall", "antonym": "short" },\n]',
    //     },
    //     {
    //       label: "Example Prompt",
    //       name: "examplePrompt",
    //       type: "PromptTemplate",
    //     },
    //     {
    //       label: "Prefix",
    //       name: "prefix",
    //       type: "string",
    //       rows: 4,
    //       placeholder: "Give the antonym of every input",
    //     },
    //     {
    //       label: "Suffix",
    //       name: "suffix",
    //       type: "string",
    //       rows: 4,
    //       placeholder: "Word: {input}\nAntonym:",
    //     },
    //     {
    //       label: "Example Seperator",
    //       name: "exampleSeparator",
    //       type: "string",
    //       placeholder: "\n\n",
    //     },
    //     {
    //       label: "Template Format",
    //       name: "templateFormat",
    //       type: "options",
    //       options: [
    //         {
    //           label: "f-string",
    //           name: "f-string",
    //         },
    //         {
    //           label: "jinja-2",
    //           name: "jinja-2",
    //         },
    //       ],
    //       default: "f-string",
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/prompts/FewShotPromptTemplate/FewShotPromptTemplate.js",
    // },
    // {
    //   label: "Prompt Template",
    //   name: "promptTemplate",
    //   type: "PromptTemplate",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/prompts/PromptTemplate/prompt.svg",
    //   category: "Prompts",
    //   description: "Schema to represent a basic prompt for an LLM",
    //   baseClasses: [
    //     "PromptTemplate",
    //     "BaseStringPromptTemplate",
    //     "BasePromptTemplate",
    //   ],
    //   inputs: [
    //     {
    //       label: "Template",
    //       name: "template",
    //       type: "string",
    //       rows: 4,
    //       placeholder:
    //         "What is a good name for a company that makes {product}?",
    //     },
    //     {
    //       label: "Format Prompt Values",
    //       name: "promptValues",
    //       type: "json",
    //       optional: true,
    //       acceptVariable: true,
    //       list: true,
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/prompts/PromptTemplate/PromptTemplate.js",
    // },
    // {
    //   label: "Prompt Retriever",
    //   name: "promptRetriever",
    //   type: "PromptRetriever",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/retrievers/PromptRetriever/promptretriever.svg",
    //   category: "Retrievers",
    //   description:
    //     "Store prompt template with name & description to be later queried by MultiPromptChain",
    //   baseClasses: ["PromptRetriever"],
    //   inputs: [
    //     {
    //       label: "Prompt Name",
    //       name: "name",
    //       type: "string",
    //       placeholder: "physics-qa",
    //     },
    //     {
    //       label: "Prompt Description",
    //       name: "description",
    //       type: "string",
    //       rows: 3,
    //       description:
    //         "Description of what the prompt does and when it should be used",
    //       placeholder: "Good for answering questions about physics",
    //     },
    //     {
    //       label: "Prompt System Message",
    //       name: "systemMessage",
    //       type: "string",
    //       rows: 4,
    //       placeholder:
    //         "You are a very smart physics professor. You are great at answering questions about physics in a concise and easy to understand manner. When you don't know the answer to a question you admit that you don't know.",
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/retrievers/PromptRetriever/PromptRetriever.js",
    // },
    // {
    //   label: "Vector Store Retriever",
    //   name: "vectorStoreRetriever",
    //   type: "VectorStoreRetriever",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/retrievers/VectorStoreRetriever/vectorretriever.svg",
    //   category: "Retrievers",
    //   description:
    //     "Store vector store as retriever to be later queried by MultiRetrievalQAChain",
    //   baseClasses: ["VectorStoreRetriever"],
    //   inputs: [
    //     {
    //       label: "Vector Store",
    //       name: "vectorStore",
    //       type: "VectorStore",
    //     },
    //     {
    //       label: "Retriever Name",
    //       name: "name",
    //       type: "string",
    //       placeholder: "netflix movies",
    //     },
    //     {
    //       label: "Retriever Description",
    //       name: "description",
    //       type: "string",
    //       rows: 3,
    //       description: "Description of when to use the vector store retriever",
    //       placeholder: "Good for answering questions about netflix movies",
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/retrievers/VectorStoreRetriever/VectorStoreRetriever.js",
    // },
    // {
    //   label: "Character Text Splitter",
    //   name: "characterTextSplitter",
    //   type: "CharacterTextSplitter",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/textsplitters/CharacterTextSplitter/textsplitter.svg",
    //   category: "Text Splitters",
    //   description:
    //     'splits only on one type of character (defaults to "\\n\\n").',
    //   baseClasses: ["CharacterTextSplitter", "TextSplitter"],
    //   inputs: [
    //     {
    //       label: "Separator",
    //       name: "separator",
    //       type: "string",
    //       optional: true,
    //     },
    //     {
    //       label: "Chunk Size",
    //       name: "chunkSize",
    //       type: "number",
    //       default: 1000,
    //       optional: true,
    //     },
    //     {
    //       label: "Chunk Overlap",
    //       name: "chunkOverlap",
    //       type: "number",
    //       optional: true,
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/textsplitters/CharacterTextSplitter/CharacterTextSplitter.js",
    // },
    // {
    //   label: "Code Text Splitter",
    //   name: "codeTextSplitter",
    //   type: "CodeTextSplitter",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/textsplitters/CodeTextSplitter/codeTextSplitter.svg",
    //   category: "Text Splitters",
    //   description: "Split documents based on language-specific syntax",
    //   baseClasses: ["CodeTextSplitter", "TextSplitter"],
    //   inputs: [
    //     {
    //       label: "Language",
    //       name: "language",
    //       type: "options",
    //       options: [
    //         {
    //           label: "cpp",
    //           name: "cpp",
    //         },
    //         {
    //           label: "go",
    //           name: "go",
    //         },
    //         {
    //           label: "java",
    //           name: "java",
    //         },
    //         {
    //           label: "js",
    //           name: "js",
    //         },
    //         {
    //           label: "php",
    //           name: "php",
    //         },
    //         {
    //           label: "proto",
    //           name: "proto",
    //         },
    //         {
    //           label: "python",
    //           name: "python",
    //         },
    //         {
    //           label: "rst",
    //           name: "rst",
    //         },
    //         {
    //           label: "ruby",
    //           name: "ruby",
    //         },
    //         {
    //           label: "rust",
    //           name: "rust",
    //         },
    //         {
    //           label: "scala",
    //           name: "scala",
    //         },
    //         {
    //           label: "swift",
    //           name: "swift",
    //         },
    //         {
    //           label: "markdown",
    //           name: "markdown",
    //         },
    //         {
    //           label: "latex",
    //           name: "latex",
    //         },
    //         {
    //           label: "html",
    //           name: "html",
    //         },
    //         {
    //           label: "sol",
    //           name: "sol",
    //         },
    //       ],
    //     },
    //     {
    //       label: "Chunk Size",
    //       name: "chunkSize",
    //       type: "number",
    //       default: 1000,
    //       optional: true,
    //     },
    //     {
    //       label: "Chunk Overlap",
    //       name: "chunkOverlap",
    //       type: "number",
    //       optional: true,
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/textsplitters/CodeTextSplitter/CodeTextSplitter.js",
    // },
    // {
    //   label: "Markdown Text Splitter",
    //   name: "markdownTextSplitter",
    //   type: "MarkdownTextSplitter",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/textsplitters/MarkdownTextSplitter/markdownTextSplitter.svg",
    //   category: "Text Splitters",
    //   description:
    //     "Split your content into documents based on the Markdown headers",
    //   baseClasses: [
    //     "MarkdownTextSplitter",
    //     "RecursiveCharacterTextSplitter",
    //     "TextSplitter",
    //   ],
    //   inputs: [
    //     {
    //       label: "Chunk Size",
    //       name: "chunkSize",
    //       type: "number",
    //       default: 1000,
    //       optional: true,
    //     },
    //     {
    //       label: "Chunk Overlap",
    //       name: "chunkOverlap",
    //       type: "number",
    //       optional: true,
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/textsplitters/MarkdownTextSplitter/MarkdownTextSplitter.js",
    // },
    // {
    //   label: "Recursive Character Text Splitter",
    //   name: "recursiveCharacterTextSplitter",
    //   type: "RecursiveCharacterTextSplitter",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/textsplitters/RecursiveCharacterTextSplitter/textsplitter.svg",
    //   category: "Text Splitters",
    //   description:
    //     'Split documents recursively by different characters - starting with "\\n\\n", then "\\n", then " "',
    //   baseClasses: ["RecursiveCharacterTextSplitter", "TextSplitter"],
    //   inputs: [
    //     {
    //       label: "Chunk Size",
    //       name: "chunkSize",
    //       type: "number",
    //       default: 1000,
    //       optional: true,
    //     },
    //     {
    //       label: "Chunk Overlap",
    //       name: "chunkOverlap",
    //       type: "number",
    //       optional: true,
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/textsplitters/RecursiveCharacterTextSplitter/RecursiveCharacterTextSplitter.js",
    // },
    // {
    //   label: "Token Text Splitter",
    //   name: "tokenTextSplitter",
    //   type: "TokenTextSplitter",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/textsplitters/TokenTextSplitter/tiktoken.svg",
    //   category: "Text Splitters",
    //   description:
    //     "Splits a raw text string by first converting the text into BPE tokens, then split these tokens into chunks and convert the tokens within a single chunk back into text.",
    //   baseClasses: ["TokenTextSplitter", "TextSplitter"],
    //   inputs: [
    //     {
    //       label: "Encoding Name",
    //       name: "encodingName",
    //       type: "options",
    //       options: [
    //         {
    //           label: "gpt2",
    //           name: "gpt2",
    //         },
    //         {
    //           label: "r50k_base",
    //           name: "r50k_base",
    //         },
    //         {
    //           label: "p50k_base",
    //           name: "p50k_base",
    //         },
    //         {
    //           label: "p50k_edit",
    //           name: "p50k_edit",
    //         },
    //         {
    //           label: "cl100k_base",
    //           name: "cl100k_base",
    //         },
    //       ],
    //       default: "gpt2",
    //     },
    //     {
    //       label: "Chunk Size",
    //       name: "chunkSize",
    //       type: "number",
    //       default: 1000,
    //       optional: true,
    //     },
    //     {
    //       label: "Chunk Overlap",
    //       name: "chunkOverlap",
    //       type: "number",
    //       optional: true,
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/textsplitters/TokenTextSplitter/TokenTextSplitter.js",
    // },
    // {
    //   label: "AI Plugin",
    //   name: "aiPlugin",
    //   type: "AIPlugin",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/tools/AIPlugin/aiplugin.svg",
    //   category: "Tools",
    //   description: "Execute actions using ChatGPT Plugin Url",
    //   baseClasses: ["AIPlugin", "Tool", "StructuredTool"],
    //   inputs: [
    //     {
    //       label: "Plugin Url",
    //       name: "pluginUrl",
    //       type: "string",
    //       placeholder: "https://www.klarna.com/.well-known/ai-plugin.json",
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/tools/AIPlugin/AIPlugin.js",
    // },
    // {
    //   label: "Calculator",
    //   name: "calculator",
    //   type: "Calculator",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/tools/Calculator/calculator.svg",
    //   category: "Tools",
    //   description: "Perform calculations on response",
    //   baseClasses: ["Calculator", "Tool", "StructuredTool"],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/tools/Calculator/Calculator.js",
    // },
    // {
    //   label: "Chain Tool",
    //   name: "chainTool",
    //   type: "ChainTool",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/tools/ChainTool/chaintool.svg",
    //   category: "Tools",
    //   description: "Use a chain as allowed tool for agent",
    //   baseClasses: ["ChainTool", "DynamicTool", "Tool", "StructuredTool"],
    //   inputs: [
    //     {
    //       label: "Chain Name",
    //       name: "name",
    //       type: "string",
    //       placeholder: "state-of-union-qa",
    //     },
    //     {
    //       label: "Chain Description",
    //       name: "description",
    //       type: "string",
    //       rows: 3,
    //       placeholder:
    //         "State of the Union QA - useful for when you need to ask questions about the most recent state of the union address.",
    //     },
    //     {
    //       label: "Return Direct",
    //       name: "returnDirect",
    //       type: "boolean",
    //       optional: true,
    //     },
    //     {
    //       label: "Base Chain",
    //       name: "baseChain",
    //       type: "BaseChain",
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/tools/ChainTool/ChainTool.js",
    // },
    // {
    //   loadMethods: {},
    //   label: "Custom Tool",
    //   name: "customTool",
    //   type: "CustomTool",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/tools/CustomTool/customtool.svg",
    //   category: "Tools",
    //   description: "Use custom tool you've created in Flowise within chatflow",
    //   inputs: [
    //     {
    //       label: "Select Tool",
    //       name: "selectedTool",
    //       type: "asyncOptions",
    //       loadMethod: "listTools",
    //     },
    //   ],
    //   baseClasses: ["CustomTool", "Tool", "StructuredTool"],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/tools/CustomTool/CustomTool.js",
    // },
    // {
    //   label: "Make.com Webhook",
    //   name: "makeWebhook",
    //   type: "MakeWebhook",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/tools/MakeWebhook/make.png",
    //   category: "Tools",
    //   description: "Execute webhook calls on Make.com",
    //   inputs: [
    //     {
    //       label: "Webhook Url",
    //       name: "url",
    //       type: "string",
    //       placeholder: "https://hook.eu1.make.com/abcdefg",
    //     },
    //     {
    //       label: "Tool Description",
    //       name: "desc",
    //       type: "string",
    //       rows: 4,
    //       placeholder: "Useful when need to send message to Discord",
    //     },
    //   ],
    //   baseClasses: ["MakeWebhook", "Tool", "StructuredTool"],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/tools/MakeWebhook/MakeWebhook.js",
    // },
    // {
    //   label: "OpenAPI Toolkit",
    //   name: "openAPIToolkit",
    //   type: "OpenAPIToolkit",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/tools/OpenAPIToolkit/openapi.png",
    //   category: "Tools",
    //   description: "Load OpenAPI specification",
    //   inputs: [
    //     {
    //       label: "OpenAI API Key",
    //       name: "openAIApiKey",
    //       type: "password",
    //     },
    //     {
    //       label: "Language Model",
    //       name: "model",
    //       type: "BaseLanguageModel",
    //     },
    //     {
    //       label: "YAML File",
    //       name: "yamlFile",
    //       type: "file",
    //       fileType: ".yaml",
    //     },
    //   ],
    //   baseClasses: ["OpenAPIToolkit", "Tool"],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/tools/OpenAPIToolkit/OpenAPIToolkit.js",
    // },
    // {
    //   label: "Read File",
    //   name: "readFile",
    //   type: "ReadFile",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/tools/ReadFile/readfile.svg",
    //   category: "Tools",
    //   description: "Read file from disk",
    //   baseClasses: ["ReadFile", "Tool", "StructuredTool"],
    //   inputs: [
    //     {
    //       label: "Base Path",
    //       name: "basePath",
    //       placeholder: "C:\\Users\\User\\Desktop",
    //       type: "string",
    //       optional: true,
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/tools/ReadFile/ReadFile.js",
    // },
    // {
    //   label: "Requests Get",
    //   name: "requestsGet",
    //   type: "RequestsGet",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/tools/RequestsGet/requestsget.svg",
    //   category: "Tools",
    //   description: "Execute HTTP GET requests",
    //   baseClasses: ["RequestsGet", "Tool", "StructuredTool"],
    //   inputs: [
    //     {
    //       label: "URL",
    //       name: "url",
    //       type: "string",
    //       description:
    //         "Agent will make call to this exact URL. If not specified, agent will try to figure out itself from AIPlugin if provided",
    //       additionalParams: true,
    //       optional: true,
    //     },
    //     {
    //       label: "Description",
    //       name: "description",
    //       type: "string",
    //       rows: 4,
    //       default:
    //         "A portal to the internet. Use this when you need to get specific content from a website. \nInput should be a  url (i.e. https://www.google.com). The output will be the text response of the GET request.",
    //       description:
    //         "Acts like a prompt to tell agent when it should use this tool",
    //       additionalParams: true,
    //       optional: true,
    //     },
    //     {
    //       label: "Headers",
    //       name: "headers",
    //       type: "json",
    //       additionalParams: true,
    //       optional: true,
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/tools/RequestsGet/RequestsGet.js",
    // },
    // {
    //   label: "Requests Post",
    //   name: "requestsPost",
    //   type: "RequestsPost",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/tools/RequestsPost/requestspost.svg",
    //   category: "Tools",
    //   description: "Execute HTTP POST requests",
    //   baseClasses: ["RequestsPost", "Tool", "StructuredTool"],
    //   inputs: [
    //     {
    //       label: "URL",
    //       name: "url",
    //       type: "string",
    //       description:
    //         "Agent will make call to this exact URL. If not specified, agent will try to figure out itself from AIPlugin if provided",
    //       additionalParams: true,
    //       optional: true,
    //     },
    //     {
    //       label: "Body",
    //       name: "body",
    //       type: "json",
    //       description:
    //         "JSON body for the POST request. If not specified, agent will try to figure out itself from AIPlugin if provided",
    //       additionalParams: true,
    //       optional: true,
    //     },
    //     {
    //       label: "Description",
    //       name: "description",
    //       type: "string",
    //       rows: 4,
    //       default:
    //         'Use this when you want to POST to a website.\nInput should be a json string with two keys: "url" and "data".\nThe value of "url" should be a string, and the value of "data" should be a dictionary of \nkey-value pairs you want to POST to the url as a JSON body.\nBe careful to always use double quotes for strings in the json string\nThe output will be the text response of the POST request.',
    //       description:
    //         "Acts like a prompt to tell agent when it should use this tool",
    //       additionalParams: true,
    //       optional: true,
    //     },
    //     {
    //       label: "Headers",
    //       name: "headers",
    //       type: "json",
    //       additionalParams: true,
    //       optional: true,
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/tools/RequestsPost/RequestsPost.js",
    // },
    // {
    //   label: "Serp API",
    //   name: "serpAPI",
    //   type: "SerpAPI",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/tools/SerpAPI/serp.png",
    //   category: "Tools",
    //   description:
    //     "Wrapper around SerpAPI - a real-time API to access Google search results",
    //   inputs: [
    //     {
    //       label: "Serp Api Key",
    //       name: "apiKey",
    //       type: "password",
    //     },
    //   ],
    //   baseClasses: ["SerpAPI", "Tool", "StructuredTool"],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/tools/SerpAPI/SerpAPI.js",
    // },
    // {
    //   label: "Serper",
    //   name: "serper",
    //   type: "Serper",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/tools/Serper/serper.png",
    //   category: "Tools",
    //   description: "Wrapper around Serper.dev - Google Search API",
    //   inputs: [
    //     {
    //       label: "Serper Api Key",
    //       name: "apiKey",
    //       type: "password",
    //     },
    //   ],
    //   baseClasses: ["Serper", "Tool", "StructuredTool"],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/tools/Serper/Serper.js",
    // },
    // {
    //   label: "Web Browser",
    //   name: "webBrowser",
    //   type: "WebBrowser",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/tools/WebBrowser/webBrowser.svg",
    //   category: "Tools",
    //   description:
    //     "Gives agent the ability to visit a website and extract information",
    //   inputs: [
    //     {
    //       label: "Language Model",
    //       name: "model",
    //       type: "BaseLanguageModel",
    //     },
    //     {
    //       label: "Embeddings",
    //       name: "embeddings",
    //       type: "Embeddings",
    //     },
    //   ],
    //   baseClasses: ["WebBrowser", "Tool", "StructuredTool"],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/tools/WebBrowser/WebBrowser.js",
    // },
    // {
    //   label: "Write File",
    //   name: "writeFile",
    //   type: "WriteFile",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/tools/WriteFile/writefile.svg",
    //   category: "Tools",
    //   description: "Write file to disk",
    //   baseClasses: ["WriteFile", "Tool", "StructuredTool"],
    //   inputs: [
    //     {
    //       label: "Base Path",
    //       name: "basePath",
    //       placeholder: "C:\\Users\\User\\Desktop",
    //       type: "string",
    //       optional: true,
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/tools/WriteFile/WriteFile.js",
    // },
    // {
    //   label: "Zapier NLA",
    //   name: "zapierNLA",
    //   type: "ZapierNLA",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/tools/ZapierNLA/zapier.png",
    //   category: "Tools",
    //   description:
    //     "Access to apps and actions on Zapier's platform through a natural language API interface",
    //   inputs: [
    //     {
    //       label: "Zapier NLA Api Key",
    //       name: "apiKey",
    //       type: "password",
    //     },
    //   ],
    //   baseClasses: ["ZapierNLA", "Tool"],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/tools/ZapierNLA/ZapierNLA.js",
    // },
    // {
    //   label: "Chroma Load Existing Index",
    //   name: "chromaExistingIndex",
    //   type: "Chroma",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/vectorstores/Chroma_Existing/chroma.svg",
    //   category: "Vector Stores",
    //   description:
    //     "Load existing index from Chroma (i.e: Document has been upserted)",
    //   baseClasses: ["Chroma", "VectorStoreRetriever", "BaseRetriever"],
    //   inputs: [
    //     {
    //       label: "Embeddings",
    //       name: "embeddings",
    //       type: "Embeddings",
    //     },
    //     {
    //       label: "Collection Name",
    //       name: "collectionName",
    //       type: "string",
    //     },
    //     {
    //       label: "Chroma URL",
    //       name: "chromaURL",
    //       type: "string",
    //       optional: true,
    //     },
    //     {
    //       label: "Top K",
    //       name: "topK",
    //       description: "Number of top results to fetch. Default to 4",
    //       placeholder: "4",
    //       type: "number",
    //       additionalParams: true,
    //       optional: true,
    //     },
    //   ],
    //   outputs: [
    //     {
    //       label: "Chroma Retriever",
    //       name: "retriever",
    //       baseClasses: ["Chroma", "VectorStoreRetriever", "BaseRetriever"],
    //     },
    //     {
    //       label: "Chroma Vector Store",
    //       name: "vectorStore",
    //       baseClasses: ["Chroma", "VectorStore"],
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/vectorstores/Chroma_Existing/Chroma_Existing.js",
    // },
    // {
    //   label: "Chroma Upsert Document",
    //   name: "chromaUpsert",
    //   type: "Chroma",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/vectorstores/Chroma_Upsert/chroma.svg",
    //   category: "Vector Stores",
    //   description: "Upsert documents to Chroma",
    //   baseClasses: ["Chroma", "VectorStoreRetriever", "BaseRetriever"],
    //   inputs: [
    //     {
    //       label: "Document",
    //       name: "document",
    //       type: "Document",
    //       list: true,
    //     },
    //     {
    //       label: "Embeddings",
    //       name: "embeddings",
    //       type: "Embeddings",
    //     },
    //     {
    //       label: "Collection Name",
    //       name: "collectionName",
    //       type: "string",
    //     },
    //     {
    //       label: "Chroma URL",
    //       name: "chromaURL",
    //       type: "string",
    //       optional: true,
    //     },
    //     {
    //       label: "Top K",
    //       name: "topK",
    //       description: "Number of top results to fetch. Default to 4",
    //       placeholder: "4",
    //       type: "number",
    //       additionalParams: true,
    //       optional: true,
    //     },
    //   ],
    //   outputs: [
    //     {
    //       label: "Chroma Retriever",
    //       name: "retriever",
    //       baseClasses: ["Chroma", "VectorStoreRetriever", "BaseRetriever"],
    //     },
    //     {
    //       label: "Chroma Vector Store",
    //       name: "vectorStore",
    //       baseClasses: ["Chroma", "VectorStore"],
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/vectorstores/Chroma_Upsert/Chroma_Upsert.js",
    // },
    // {
    //   label: "Faiss Load Existing Index",
    //   name: "faissExistingIndex",
    //   type: "Faiss",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/vectorstores/Faiss_Existing/faiss.svg",
    //   category: "Vector Stores",
    //   description:
    //     "Load existing index from Faiss (i.e: Document has been upserted)",
    //   baseClasses: ["Faiss", "VectorStoreRetriever", "BaseRetriever"],
    //   inputs: [
    //     {
    //       label: "Embeddings",
    //       name: "embeddings",
    //       type: "Embeddings",
    //     },
    //     {
    //       label: "Base Path to load",
    //       name: "basePath",
    //       description: "Path to load faiss.index file",
    //       placeholder: "C:\\Users\\User\\Desktop",
    //       type: "string",
    //     },
    //     {
    //       label: "Top K",
    //       name: "topK",
    //       description: "Number of top results to fetch. Default to 4",
    //       placeholder: "4",
    //       type: "number",
    //       additionalParams: true,
    //       optional: true,
    //     },
    //   ],
    //   outputs: [
    //     {
    //       label: "Faiss Retriever",
    //       name: "retriever",
    //       baseClasses: ["Faiss", "VectorStoreRetriever", "BaseRetriever"],
    //     },
    //     {
    //       label: "Faiss Vector Store",
    //       name: "vectorStore",
    //       baseClasses: ["Faiss", "SaveableVectorStore", "VectorStore"],
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/vectorstores/Faiss_Existing/Faiss_Existing.js",
    // },
    // {
    //   label: "Faiss Upsert Document",
    //   name: "faissUpsert",
    //   type: "Faiss",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/vectorstores/Faiss_Upsert/faiss.svg",
    //   category: "Vector Stores",
    //   description: "Upsert documents to Faiss",
    //   baseClasses: ["Faiss", "VectorStoreRetriever", "BaseRetriever"],
    //   inputs: [
    //     {
    //       label: "Document",
    //       name: "document",
    //       type: "Document",
    //       list: true,
    //     },
    //     {
    //       label: "Embeddings",
    //       name: "embeddings",
    //       type: "Embeddings",
    //     },
    //     {
    //       label: "Base Path to store",
    //       name: "basePath",
    //       description: "Path to store faiss.index file",
    //       placeholder: "C:\\Users\\User\\Desktop",
    //       type: "string",
    //     },
    //     {
    //       label: "Top K",
    //       name: "topK",
    //       description: "Number of top results to fetch. Default to 4",
    //       placeholder: "4",
    //       type: "number",
    //       additionalParams: true,
    //       optional: true,
    //     },
    //   ],
    //   outputs: [
    //     {
    //       label: "Faiss Retriever",
    //       name: "retriever",
    //       baseClasses: ["Faiss", "VectorStoreRetriever", "BaseRetriever"],
    //     },
    //     {
    //       label: "Faiss Vector Store",
    //       name: "vectorStore",
    //       baseClasses: ["Faiss", "SaveableVectorStore", "VectorStore"],
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/vectorstores/Faiss_Upsert/Faiss_Upsert.js",
    // },
    // {
    //   label: "In-Memory Vector Store",
    //   name: "memoryVectorStore",
    //   type: "Memory",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/vectorstores/InMemory/memory.svg",
    //   category: "Vector Stores",
    //   description:
    //     "In-memory vectorstore that stores embeddings and does an exact, linear search for the most similar embeddings.",
    //   baseClasses: ["Memory", "VectorStoreRetriever", "BaseRetriever"],
    //   inputs: [
    //     {
    //       label: "Document",
    //       name: "document",
    //       type: "Document",
    //       list: true,
    //     },
    //     {
    //       label: "Embeddings",
    //       name: "embeddings",
    //       type: "Embeddings",
    //     },
    //     {
    //       label: "Top K",
    //       name: "topK",
    //       description: "Number of top results to fetch. Default to 4",
    //       placeholder: "4",
    //       type: "number",
    //       optional: true,
    //     },
    //   ],
    //   outputs: [
    //     {
    //       label: "Memory Retriever",
    //       name: "retriever",
    //       baseClasses: ["Memory", "VectorStoreRetriever", "BaseRetriever"],
    //     },
    //     {
    //       label: "Memory Vector Store",
    //       name: "vectorStore",
    //       baseClasses: ["Memory", "VectorStore"],
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/vectorstores/InMemory/InMemoryVectorStore.js",
    // },
    // {
    //   label: "Pinecone Load Existing Index",
    //   name: "pineconeExistingIndex",
    //   type: "Pinecone",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/vectorstores/Pinecone_Existing/pinecone.png",
    //   category: "Vector Stores",
    //   description:
    //     "Load existing index from Pinecone (i.e: Document has been upserted)",
    //   baseClasses: ["Pinecone", "VectorStoreRetriever", "BaseRetriever"],
    //   inputs: [
    //     {
    //       label: "Embeddings",
    //       name: "embeddings",
    //       type: "Embeddings",
    //     },
    //     {
    //       label: "Pinecone Api Key",
    //       name: "pineconeApiKey",
    //       type: "password",
    //     },
    //     {
    //       label: "Pinecone Environment",
    //       name: "pineconeEnv",
    //       type: "string",
    //     },
    //     {
    //       label: "Pinecone Index",
    //       name: "pineconeIndex",
    //       type: "string",
    //     },
    //     {
    //       label: "Pinecone Namespace",
    //       name: "pineconeNamespace",
    //       type: "string",
    //       placeholder: "my-first-namespace",
    //       additionalParams: true,
    //       optional: true,
    //     },
    //     {
    //       label: "Pinecone Metadata Filter",
    //       name: "pineconeMetadataFilter",
    //       type: "json",
    //       optional: true,
    //       additionalParams: true,
    //     },
    //     {
    //       label: "Top K",
    //       name: "topK",
    //       description: "Number of top results to fetch. Default to 4",
    //       placeholder: "4",
    //       type: "number",
    //       additionalParams: true,
    //       optional: true,
    //     },
    //   ],
    //   outputs: [
    //     {
    //       label: "Pinecone Retriever",
    //       name: "retriever",
    //       baseClasses: ["Pinecone", "VectorStoreRetriever", "BaseRetriever"],
    //     },
    //     {
    //       label: "Pinecone Vector Store",
    //       name: "vectorStore",
    //       baseClasses: ["Pinecone", "VectorStore"],
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/vectorstores/Pinecone_Existing/Pinecone_Existing.js",
    // },
    // {
    //   label: "Pinecone Upsert Document",
    //   name: "pineconeUpsert",
    //   type: "Pinecone",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/vectorstores/Pinecone_Upsert/pinecone.png",
    //   category: "Vector Stores",
    //   description: "Upsert documents to Pinecone",
    //   baseClasses: ["Pinecone", "VectorStoreRetriever", "BaseRetriever"],
    //   inputs: [
    //     {
    //       label: "Document",
    //       name: "document",
    //       type: "Document",
    //       list: true,
    //     },
    //     {
    //       label: "Embeddings",
    //       name: "embeddings",
    //       type: "Embeddings",
    //     },
    //     {
    //       label: "Pinecone Api Key",
    //       name: "pineconeApiKey",
    //       type: "password",
    //     },
    //     {
    //       label: "Pinecone Environment",
    //       name: "pineconeEnv",
    //       type: "string",
    //     },
    //     {
    //       label: "Pinecone Index",
    //       name: "pineconeIndex",
    //       type: "string",
    //     },
    //     {
    //       label: "Pinecone Namespace",
    //       name: "pineconeNamespace",
    //       type: "string",
    //       placeholder: "my-first-namespace",
    //       additionalParams: true,
    //       optional: true,
    //     },
    //     {
    //       label: "Top K",
    //       name: "topK",
    //       description: "Number of top results to fetch. Default to 4",
    //       placeholder: "4",
    //       type: "number",
    //       additionalParams: true,
    //       optional: true,
    //     },
    //   ],
    //   outputs: [
    //     {
    //       label: "Pinecone Retriever",
    //       name: "retriever",
    //       baseClasses: ["Pinecone", "VectorStoreRetriever", "BaseRetriever"],
    //     },
    //     {
    //       label: "Pinecone Vector Store",
    //       name: "vectorStore",
    //       baseClasses: ["Pinecone", "VectorStore"],
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/vectorstores/Pinecone_Upsert/Pinecone_Upsert.js",
    // },
    // {
    //   label: "Qdrant Load Existing Index",
    //   name: "qdrantExistingIndex",
    //   type: "Qdrant",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/vectorstores/Qdrant_Existing/qdrant_logo.svg",
    //   category: "Vector Stores",
    //   description:
    //     "Load existing index from Qdrant (i.e., documents have been upserted)",
    //   baseClasses: ["Qdrant", "VectorStoreRetriever", "BaseRetriever"],
    //   inputs: [
    //     {
    //       label: "Embeddings",
    //       name: "embeddings",
    //       type: "Embeddings",
    //     },
    //     {
    //       label: "Qdrant Server URL",
    //       name: "qdrantServerUrl",
    //       type: "string",
    //       placeholder: "http://localhost:6333",
    //     },
    //     {
    //       label: "Qdrant Collection Name",
    //       name: "qdrantCollection",
    //       type: "string",
    //     },
    //     {
    //       label: "Qdrant API Key",
    //       name: "qdrantApiKey",
    //       type: "password",
    //       optional: true,
    //     },
    //     {
    //       label: "Qdrant Collection Cofiguration",
    //       name: "qdrantCollectionCofiguration",
    //       type: "json",
    //       optional: true,
    //       additionalParams: true,
    //     },
    //     {
    //       label: "Top K",
    //       name: "topK",
    //       description: "Number of top results to fetch. Default to 4",
    //       placeholder: "4",
    //       type: "number",
    //       additionalParams: true,
    //       optional: true,
    //     },
    //   ],
    //   outputs: [
    //     {
    //       label: "Qdrant Retriever",
    //       name: "retriever",
    //       baseClasses: ["Qdrant", "VectorStoreRetriever", "BaseRetriever"],
    //     },
    //     {
    //       label: "Qdrant Vector Store",
    //       name: "vectorStore",
    //       baseClasses: ["Qdrant", "VectorStore"],
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/vectorstores/Qdrant_Existing/Qdrant_Existing.js",
    // },
    // {
    //   label: "Qdrant Upsert Document",
    //   name: "qdrantUpsert",
    //   type: "Qdrant",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/vectorstores/Qdrant_Upsert/qdrant_logo.svg",
    //   category: "Vector Stores",
    //   description: "Upsert documents to Qdrant",
    //   baseClasses: ["Qdrant", "VectorStoreRetriever", "BaseRetriever"],
    //   inputs: [
    //     {
    //       label: "Document",
    //       name: "document",
    //       type: "Document",
    //       list: true,
    //     },
    //     {
    //       label: "Embeddings",
    //       name: "embeddings",
    //       type: "Embeddings",
    //     },
    //     {
    //       label: "Qdrant Server URL",
    //       name: "qdrantServerUrl",
    //       type: "string",
    //       placeholder: "http://localhost:6333",
    //     },
    //     {
    //       label: "Qdrant Collection Name",
    //       name: "qdrantCollection",
    //       type: "string",
    //     },
    //     {
    //       label: "Qdrant API Key",
    //       name: "qdrantApiKey",
    //       type: "password",
    //       optional: true,
    //     },
    //     {
    //       label: "Top K",
    //       name: "topK",
    //       description: "Number of top results to fetch. Default to 4",
    //       placeholder: "4",
    //       type: "number",
    //       additionalParams: true,
    //       optional: true,
    //     },
    //   ],
    //   outputs: [
    //     {
    //       label: "Qdrant Retriever",
    //       name: "retriever",
    //       baseClasses: ["Qdrant", "VectorStoreRetriever", "BaseRetriever"],
    //     },
    //     {
    //       label: "Qdrant Vector Store",
    //       name: "vectorStore",
    //       baseClasses: ["Qdrant", "VectorStore"],
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/vectorstores/Qdrant_Upsert/Qdrant_Upsert.js",
    // },
    // {
    //   label: "Supabase Load Existing Index",
    //   name: "supabaseExistingIndex",
    //   type: "Supabase",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/vectorstores/Supabase_Existing/supabase.svg",
    //   category: "Vector Stores",
    //   description:
    //     "Load existing index from Supabase (i.e: Document has been upserted)",
    //   baseClasses: ["Supabase", "VectorStoreRetriever", "BaseRetriever"],
    //   inputs: [
    //     {
    //       label: "Embeddings",
    //       name: "embeddings",
    //       type: "Embeddings",
    //     },
    //     {
    //       label: "Supabase API Key",
    //       name: "supabaseApiKey",
    //       type: "password",
    //     },
    //     {
    //       label: "Supabase Project URL",
    //       name: "supabaseProjUrl",
    //       type: "string",
    //     },
    //     {
    //       label: "Table Name",
    //       name: "tableName",
    //       type: "string",
    //     },
    //     {
    //       label: "Query Name",
    //       name: "queryName",
    //       type: "string",
    //     },
    //     {
    //       label: "Supabase Metadata Filter",
    //       name: "supabaseMetadataFilter",
    //       type: "json",
    //       optional: true,
    //       additionalParams: true,
    //     },
    //     {
    //       label: "Top K",
    //       name: "topK",
    //       description: "Number of top results to fetch. Default to 4",
    //       placeholder: "4",
    //       type: "number",
    //       additionalParams: true,
    //       optional: true,
    //     },
    //   ],
    //   outputs: [
    //     {
    //       label: "Supabase Retriever",
    //       name: "retriever",
    //       baseClasses: ["Supabase", "VectorStoreRetriever", "BaseRetriever"],
    //     },
    //     {
    //       label: "Supabase Vector Store",
    //       name: "vectorStore",
    //       baseClasses: ["Supabase", "VectorStore"],
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/vectorstores/Supabase_Existing/Supabase_Exisiting.js",
    // },
    // {
    //   label: "Supabase Upsert Document",
    //   name: "supabaseUpsert",
    //   type: "Supabase",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/vectorstores/Supabase_Upsert/supabase.svg",
    //   category: "Vector Stores",
    //   description: "Upsert documents to Supabase",
    //   baseClasses: ["Supabase", "VectorStoreRetriever", "BaseRetriever"],
    //   inputs: [
    //     {
    //       label: "Document",
    //       name: "document",
    //       type: "Document",
    //       list: true,
    //     },
    //     {
    //       label: "Embeddings",
    //       name: "embeddings",
    //       type: "Embeddings",
    //     },
    //     {
    //       label: "Supabase API Key",
    //       name: "supabaseApiKey",
    //       type: "password",
    //     },
    //     {
    //       label: "Supabase Project URL",
    //       name: "supabaseProjUrl",
    //       type: "string",
    //     },
    //     {
    //       label: "Table Name",
    //       name: "tableName",
    //       type: "string",
    //     },
    //     {
    //       label: "Query Name",
    //       name: "queryName",
    //       type: "string",
    //     },
    //     {
    //       label: "Top K",
    //       name: "topK",
    //       description: "Number of top results to fetch. Default to 4",
    //       placeholder: "4",
    //       type: "number",
    //       additionalParams: true,
    //       optional: true,
    //     },
    //   ],
    //   outputs: [
    //     {
    //       label: "Supabase Retriever",
    //       name: "retriever",
    //       baseClasses: ["Supabase", "VectorStoreRetriever", "BaseRetriever"],
    //     },
    //     {
    //       label: "Supabase Vector Store",
    //       name: "vectorStore",
    //       baseClasses: ["Supabase", "VectorStore"],
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/vectorstores/Supabase_Upsert/Supabase_Upsert.js",
    // },
    // {
    //   label: "Weaviate Load Existing Index",
    //   name: "weaviateExistingIndex",
    //   type: "Weaviate",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/vectorstores/Weaviate_Existing/weaviate.png",
    //   category: "Vector Stores",
    //   description:
    //     "Load existing index from Weaviate (i.e: Document has been upserted)",
    //   baseClasses: ["Weaviate", "VectorStoreRetriever", "BaseRetriever"],
    //   inputs: [
    //     {
    //       label: "Embeddings",
    //       name: "embeddings",
    //       type: "Embeddings",
    //     },
    //     {
    //       label: "Weaviate Scheme",
    //       name: "weaviateScheme",
    //       type: "options",
    //       default: "https",
    //       options: [
    //         {
    //           label: "https",
    //           name: "https",
    //         },
    //         {
    //           label: "http",
    //           name: "http",
    //         },
    //       ],
    //     },
    //     {
    //       label: "Weaviate Host",
    //       name: "weaviateHost",
    //       type: "string",
    //       placeholder: "localhost:8080",
    //     },
    //     {
    //       label: "Weaviate Index",
    //       name: "weaviateIndex",
    //       type: "string",
    //       placeholder: "Test",
    //     },
    //     {
    //       label: "Weaviate API Key",
    //       name: "weaviateApiKey",
    //       type: "password",
    //       optional: true,
    //     },
    //     {
    //       label: "Weaviate Text Key",
    //       name: "weaviateTextKey",
    //       type: "string",
    //       placeholder: "text",
    //       optional: true,
    //       additionalParams: true,
    //     },
    //     {
    //       label: "Weaviate Metadata Keys",
    //       name: "weaviateMetadataKeys",
    //       type: "string",
    //       rows: 4,
    //       placeholder: '["foo"]',
    //       optional: true,
    //       additionalParams: true,
    //     },
    //     {
    //       label: "Top K",
    //       name: "topK",
    //       description: "Number of top results to fetch. Default to 4",
    //       placeholder: "4",
    //       type: "number",
    //       additionalParams: true,
    //       optional: true,
    //     },
    //   ],
    //   outputs: [
    //     {
    //       label: "Weaviate Retriever",
    //       name: "retriever",
    //       baseClasses: ["Weaviate", "VectorStoreRetriever", "BaseRetriever"],
    //     },
    //     {
    //       label: "Weaviate Vector Store",
    //       name: "vectorStore",
    //       baseClasses: ["Weaviate", "VectorStore"],
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/vectorstores/Weaviate_Existing/Weaviate_Existing.js",
    // },
    // {
    //   label: "Weaviate Upsert Document",
    //   name: "weaviateUpsert",
    //   type: "Weaviate",
    //   icon: "/usr/src/packages/node_modules/flowise-components/dist/nodes/vectorstores/Weaviate_Upsert/weaviate.png",
    //   category: "Vector Stores",
    //   description: "Upsert documents to Weaviate",
    //   baseClasses: ["Weaviate", "VectorStoreRetriever", "BaseRetriever"],
    //   inputs: [
    //     {
    //       label: "Document",
    //       name: "document",
    //       type: "Document",
    //       list: true,
    //     },
    //     {
    //       label: "Embeddings",
    //       name: "embeddings",
    //       type: "Embeddings",
    //     },
    //     {
    //       label: "Weaviate Scheme",
    //       name: "weaviateScheme",
    //       type: "options",
    //       default: "https",
    //       options: [
    //         {
    //           label: "https",
    //           name: "https",
    //         },
    //         {
    //           label: "http",
    //           name: "http",
    //         },
    //       ],
    //     },
    //     {
    //       label: "Weaviate Host",
    //       name: "weaviateHost",
    //       type: "string",
    //       placeholder: "localhost:8080",
    //     },
    //     {
    //       label: "Weaviate Index",
    //       name: "weaviateIndex",
    //       type: "string",
    //       placeholder: "Test",
    //     },
    //     {
    //       label: "Weaviate API Key",
    //       name: "weaviateApiKey",
    //       type: "password",
    //       optional: true,
    //     },
    //     {
    //       label: "Weaviate Text Key",
    //       name: "weaviateTextKey",
    //       type: "string",
    //       placeholder: "text",
    //       optional: true,
    //       additionalParams: true,
    //     },
    //     {
    //       label: "Weaviate Metadata Keys",
    //       name: "weaviateMetadataKeys",
    //       type: "string",
    //       rows: 4,
    //       placeholder: '["foo"]',
    //       optional: true,
    //       additionalParams: true,
    //     },
    //     {
    //       label: "Top K",
    //       name: "topK",
    //       description: "Number of top results to fetch. Default to 4",
    //       placeholder: "4",
    //       type: "number",
    //       additionalParams: true,
    //       optional: true,
    //     },
    //   ],
    //   outputs: [
    //     {
    //       label: "Weaviate Retriever",
    //       name: "retriever",
    //       baseClasses: ["Weaviate", "VectorStoreRetriever", "BaseRetriever"],
    //     },
    //     {
    //       label: "Weaviate Vector Store",
    //       name: "vectorStore",
    //       baseClasses: ["Weaviate", "VectorStore"],
    //     },
    //   ],
    //   filePath:
    //     "/usr/src/packages/node_modules/flowise-components/dist/nodes/vectorstores/Weaviate_Upsert/Weaviate_Upsert.js",
    // },
  ];
  const [searchValue, setSearchValue] = useState("");
  const [nodes, setNodes] = useState({});
  const [open, setOpen] = useState(false);
  const [categoryExpanded, setCategoryExpanded] = useState({});

  const anchorRef = useRef(null);
  const prevOpen = useRef(open);
  const ps = useRef();

  const scrollTop = () => {
    const curr = ps.current;
    if (curr) {
      curr.scrollTop = 0;
    }
  };

  const filterSearch = (value) => {
    setSearchValue(value);
    setTimeout(() => {
      if (value) {
        const returnData = nodeData.filter((nd) =>
          nd.name.toLowerCase().includes(value.toLowerCase())
        );
        groupByCategory(returnData, true);
        scrollTop();
      } else if (value === "") {
        groupByCategory(nodeData);
        scrollTop();
      }
    }, 500);
  };

  const groupByCategory = (nodes, isFilter) => {
    const accordianCategories = {};
    const result = nodes.reduce(function (r, a) {
      r[a.category] = r[a.category] || [];
      r[a.category].push(a);
      accordianCategories[a.category] = isFilter ? true : false;
      return r;
    }, Object.create(null));
    setNodes(result);
    setCategoryExpanded(accordianCategories);
  };

  const handleAccordionChange = (category) => (event, isExpanded) => {
    const accordianCategories = { ...categoryExpanded };
    accordianCategories[category] = isExpanded;
    setCategoryExpanded(accordianCategories);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const onDragStart = (event, node) => {
    event.dataTransfer.setData("application/reactflow", JSON.stringify(node));
    event.dataTransfer.effectAllowed = "move";
  };

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    if (node) setOpen(false);
  }, [node]);

  useEffect(() => {
    if (nodeData) groupByCategory(nodeData);
  }, []);

  return (
    <>
      <StyledFab
        sx={{ left: 20, top: 20 }}
        ref={anchorRef}
        size="small"
        color="primary"
        aria-label="add"
        title="Add Node"
        onClick={handleToggle}
      >
        {open ? <IconMinus /> : <IconPlus />}
      </StyledFab>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [-40, 14],
              },
            },
          ],
        }}
        sx={{ zIndex: 1000 }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  border={false}
                  elevation={16}
                  content={false}
                  boxShadow
                  shadow={theme.shadows[16]}
                >
                  <Box sx={{ p: 2 }}>
                    <Stack>
                      <Typography variant="h4">Add Nodes</Typography>
                    </Stack>
                    <OutlinedInput
                      sx={{ width: "100%", pr: 1, pl: 2, my: 2 }}
                      id="input-search-node"
                      value={searchValue}
                      onChange={(e) => filterSearch(e.target.value)}
                      placeholder="Search nodes"
                      startAdornment={
                        <InputAdornment position="start">
                          <IconSearch
                            stroke={1.5}
                            size="1rem"
                            color={theme.palette.grey[500]}
                          />
                        </InputAdornment>
                      }
                      aria-describedby="search-helper-text"
                      inputProps={{
                        "aria-label": "weight",
                      }}
                    />
                    <Divider />
                  </Box>
                  <PerfectScrollbar
                    containerRef={(el) => {
                      ps.current = el;
                    }}
                    style={{
                      height: "100%",
                      maxHeight: "40vh",
                      overflowX: "hidden",
                    }}
                  >
                    <Box sx={{ p: 2 }}>
                      <List
                        sx={{
                          width: "100%",
                          maxWidth: 370,
                          py: 0,
                          borderRadius: "10px",
                          [theme.breakpoints.down("md")]: {
                            maxWidth: 370,
                          },
                          "& .MuiListItemSecondaryAction-root": {
                            top: 22,
                          },
                          "& .MuiDivider-root": {
                            my: 0,
                          },
                          "& .list-container": {
                            pl: 7,
                          },
                        }}
                      >
                        {Object.keys(nodes)
                          .sort()
                          .map((category) => (
                            <Accordion
                              expanded={categoryExpanded[category] || false}
                              onChange={handleAccordionChange(category)}
                              key={category}
                              disableGutters
                            >
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`nodes-accordian-${category}`}
                                id={`nodes-accordian-header-${category}`}
                              >
                                <Typography variant="h5">{category}</Typography>
                              </AccordionSummary>
                              {category}
                              <AccordionDetails>
                                {nodes[category].map((node, index) => (
                                  <div
                                    key={node.name}
                                    onDragStart={(event) =>
                                      onDragStart(event, node)
                                    }
                                    draggable
                                  >
                                    <ListItemButton
                                      sx={{
                                        p: 0,
                                        borderRadius: `${customization.borderRadius}px`,
                                        cursor: "move",
                                      }}
                                    >
                                      <ListItem alignItems="center">
                                        <ListItemAvatar>
                                          <div
                                            style={{
                                              width: 50,
                                              height: 50,
                                              borderRadius: "50%",
                                              backgroundColor: "white",
                                            }}
                                          >
                                            <img
                                              style={{
                                                width: "100%",
                                                height: "100%",
                                                padding: 10,
                                                objectFit: "contain",
                                              }}
                                              alt={node.name}
                                              // src={`${baseURL}/api/v1/node-icon/${node.name}`}
                                              src={require(`../../assets/images/autoGPT.png`)}
                                            />
                                          </div>
                                        </ListItemAvatar>
                                        <ListItemText
                                          sx={{ ml: 1 }}
                                          primary={node.label}
                                          secondary={node.description}
                                        />
                                      </ListItem>
                                    </ListItemButton>
                                    {index ===
                                    nodes[category]?.length - 1 ? null : (
                                      <Divider />
                                    )}
                                  </div>
                                ))}
                              </AccordionDetails>
                            </Accordion>
                          ))}
                      </List>
                    </Box>
                  </PerfectScrollbar>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  );
};

AddNodes.propTypes = {
  nodesData: PropTypes.array,
  node: PropTypes.object,
};

export default AddNodes;
