import { lazy } from "react";

// project imports
import Loadable from "ui-component/loading/Loadable";
import MinimalLayout from "layout/MinimalLayout";
import NonSubnavLayout from "pi-layout/nonSubnavLayout";
import Invite from "pi-screen/invite";
import Billing from "pi-screen/billing";
import Settings from "pi-screen/settings";
import PageNotFound from "pi-screen/page_not_found";
import OwnModal from "pi-screen/own_modal";
import ApiKey from "pi-screen/settings/apikey";
import TextToVideo from "pi-screen/usecases/simple_usecases/text_to_video";
import QADocs from "pi-screen/usecases/simple_usecases/qa_docs";
import Summarization from "pi-screen/usecases/simple_usecases/summarization";
import Video from "pi-screen/usecases/simple_usecases/video";
import EnterprisesUsecases from "pi-screen/usecases/enterprises_usecases";
import Enterprises_UseCases_ChatArea from "pi-screen/usecases/enterprises_usecases/enterprises_UseCases_ChatArea";
import UseCases from "pi-screen/usecases";
import UseCaseGlobalCardScreen from "pi-screen/usecases/simple_usecases/usecase_global_cards";

// canvas routing
const ChatbotFull = Loadable(lazy(() => import("views/chatbot")));

// ==============================|| CANVAS ROUTING ||============================== //

const NonSubnavRoute = {
  path: "/",
  element: <NonSubnavLayout />,
  children: [
    {
      path: "invite",
      element: <Invite />,
    },
    {
      path: "Billing",
      element: <Billing />,
    },
    {
      path: "setting",
      element: <Settings />,
    },
    {
      path: "setting/apikey",
      element: <ApiKey />,
    },

    {
      path: "usecases/EnterprisesUsecases/:data",
      element: <EnterprisesUsecases />,
    },
    {
      path: "usecases/EnterprisesUsecases/:data/:id",
      element: <Enterprises_UseCases_ChatArea />,
    },
    {
      path: "usecases/:data",
      element: <UseCaseGlobalCardScreen />,
    },
    {
      path: "*",
      element: <PageNotFound />,
    },
  ],
};

export default NonSubnavRoute;
