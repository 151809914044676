import React from "react";
import App from "./App";
import { store } from "store";
import { createRoot } from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/js/dist/dropdown";
import "bootstrap/js/dist/tab";
import "bootstrap/js/dist/carousel";
// import "bootstrap/js/src/collapse";
import "bootstrap/js/dist/collapse";
import "bootstrap/js/dist/modal";

// style + assets
import "assets/scss/style.scss";

// third party
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { SnackbarProvider } from "notistack";
import ConfirmContextProvider from "store/context/ConfirmContextProvider";
import { ReactFlowContext } from "store/context/ReactFlowContext";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <SnackbarProvider>
          <ConfirmContextProvider>
            <ReactFlowContext>        
                <App/>
            </ReactFlowContext>
          </ConfirmContextProvider>
        </SnackbarProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);
