import { PITHEME, UPDATE_ENTERPRISES_MODAl } from "store/actions";

const InitialUsecaseModal = {
  modalName: "",
  modalValue: [],
};

export const enterpriseUsecasModalReducer = (
  state = InitialUsecaseModal,
  { type, payload }
) => {
  switch (type) {
    case UPDATE_ENTERPRISES_MODAl: {
      return {
        ...InitialUsecaseModal,
        modalName: payload.modalName,
        modalValue: [...payload.modalValue],
      };
    }
    default: {
      return state;
    }
  }
};
const PiThemeInitialState={
  theme:"Light"
}
export const PiThemeReducer=(state=PiThemeInitialState,{ type, payload})=>{
  switch (type) {
    case PITHEME: {
      return {
        theme:payload
      };
    }
    default: {
      return state;
    }
  }
}