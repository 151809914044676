import React, { useState, useEffect, useMemo } from 'react';
import classes from './index.module.css';
import OwnModalHeader from './ownModalHeader/ownModalHeader';
import OwnMOdalWrapper from './ownModalWrapper/ownModalWrapper';

import OwnModalContextProvider from './context/OwnModalContextProvider';
import index from 'pi-screen/homeComponent';



const data = [
  [
    { set: 1 },
    {
      id: 1,
      question: 'Please provide your company name',
      mandatory: true,
      questionType: "Company Name",
    },
    {
      id: 2,
      question: 'Select the industries applicable to you',
      options: [
        {
          blImage: require('../../assets/images/ownmodal/1/AskColor/1.png'),
          clrImage: require('../../assets/images/ownmodal/1/BlueColor/1.png'),
          name: 'Retail'
        },
        {
          blImage: require('../../assets/images/ownmodal/1/AskColor/2.png'),
          clrImage: require('../../assets/images/ownmodal/1/BlueColor/2.png'),
          name: 'Consumer Goods'
        },
        {
          blImage: require('../../assets/images/ownmodal/1/AskColor/3.png'),
          clrImage: require('../../assets/images/ownmodal/1/BlueColor/3.png'),
          name: 'Banking, Finance and Insurance'
        },
        {
          blImage: require('../../assets/images/ownmodal/1/AskColor/4.png'),
          clrImage: require('../../assets/images/ownmodal/1/BlueColor/4.png'),
          name: 'Manufacturing & Logistics'
        },
        {
          blImage: require('../../assets/images/ownmodal/1/AskColor/5.png'),
          clrImage: require('../../assets/images/ownmodal/1/BlueColor/5.png'),
          name: 'Engergy & Utilities'
        },
        {
          blImage: require('../../assets/images/ownmodal/1/AskColor/6.png'),
          clrImage: require('../../assets/images/ownmodal/1/BlueColor/6.png'),
          name: 'Healthcare'
        },
        {
          blImage: require('../../assets/images/ownmodal/1/AskColor/7.png'),
          clrImage: require('../../assets/images/ownmodal/1/BlueColor/7.png'),
          name: 'LifeScience'
        },
        {
          blImage: require('../../assets/images/ownmodal/1/AskColor/8.png'),
          clrImage: require('../../assets/images/ownmodal/1/BlueColor/8.png'),
          name: 'Travel & Hospitality'
        },
      ],
      mandatory: true,
      questionType: "Industries"
    },
    {
      id: 3,
      question: 'What business problem would you like to resolve?',
      options: [
        {
          blImage: require('../../assets/images/ownmodal/2/AskColor/1.png'),
          clrImage: require('../../assets/images/ownmodal/2/BlueColor/1.png'),
          name: 'Q&A'
        },
        {
          blImage: require('../../assets/images/ownmodal/2/AskColor/2.png'),
          clrImage: require('../../assets/images/ownmodal/2/BlueColor/2.png'),
          name: 'Text Summarization'
        },
        {
          blImage: require('../../assets/images/ownmodal/2/AskColor/3.png'),
          clrImage: require('../../assets/images/ownmodal/2/BlueColor/3.png'),
          name: 'Generative AI for APIs'
        },
        {
          blImage: require('../../assets/images/ownmodal/2/AskColor/4.png'),
          clrImage: require('../../assets/images/ownmodal/2/BlueColor/4.png'),
          name: 'Integrate with your database'
        },
        {
          blImage: require('../../assets/images/ownmodal/2/AskColor/5.png'),
          clrImage: require('../../assets/images/ownmodal/2/BlueColor/5.png'),
          name: 'Chatbots'
        },
        {
          blImage: require('../../assets/images/ownmodal/2/AskColor/6.png'),
          clrImage: require('../../assets/images/ownmodal/2/BlueColor/6.png'),
          name: 'Search'
        },
        {
          blImage: require('../../assets/images/ownmodal/2/AskColor/7.png'),
          clrImage: require('../../assets/images/ownmodal/2/BlueColor/7.png'),
          name: 'Image generation'
        },
      ],
      mandatory: true,
      questionType: "Business"
    },
    {
      id: 4,
      question: 'Select the cloud platforms for hosting your services',
      options: [
        {
          blImage: require('../../assets/images/ownmodal/3/AskColor/1.png'),
          clrImage: require('../../assets/images/ownmodal/3/BlueColor/1.png'),
          name: 'AWS'
        },
        {
          blImage: require('../../assets/images/ownmodal/3/AskColor/2.png'),
          clrImage: require('../../assets/images/ownmodal/3/BlueColor/2.png'),
          name: 'GCP'
        },
        {
          blImage: require('../../assets/images/ownmodal/3/AskColor/3.png'),
          clrImage: require('../../assets/images/ownmodal/3/BlueColor/3.png'),
          name: 'Azure'
        },
        {
          blImage: require('../../assets/images/ownmodal/3/AskColor/4.png'),
          clrImage: require('../../assets/images/ownmodal/3/BlueColor/4.png'),
          name: 'Private Cloud'
        },
        {
          blImage: require('../../assets/images/ownmodal/3/AskColor/5.png'),
          clrImage: require('../../assets/images/ownmodal/3/BlueColor/5.png'),
          name: 'Scale'
        },
      ],
      mandatory: true,
      questionType: "Cloud platforms"
    }
  ],
  [
    { set: 2 },
    {
      id: 5,
      question: 'Select the foundational model(s)',
      mainCategory: [
        {
          name: 'LLM',
          category: [
            {
              name: "Opensource",
              subCategory: [
                {
                  name: 'GPT4All',
                  subCategoryList: []
                },
                {
                  name: 'Llama - cpp',
                  subCategoryList: []
                },
                {
                  name: 'UL2',
                  subCategoryList: []
                },
                {
                  name: 'Dolly',
                  subCategoryList: []
                },
                {
                  name: 'Dlite',
                  subCategoryList: []
                },
                {
                  name: 'Bloom',
                  subCategoryList: []
                },
                {
                  name: 'StableM - Alpha',
                  subCategoryList: []
                },
                {
                  name: 'RedPajama',
                  subCategoryList: []
                },
                {
                  name: 'OpenLLAMA',
                  subCategoryList: []
                },
                {
                  name: 'Falcon',
                  subCategoryList: []
                },
                {
                  name: 'BERT',
                  subCategoryList: []
                },
                {
                  name: 'T5 - FLAN',
                  subCategoryList: []
                },
                {
                  name: 'ViT',
                  subCategoryList: []
                },
                {
                  name: 'EfficientNet',
                  subCategoryList: []
                },
              ],
              img: require('../../assets/images/ownmodal/4/1.png')
            },
            {
              name: "Private",
              subCategory: [
                {
                  name: 'AI21',
                  subCategoryList: []
                },
                {
                  name: 'Aleph Alpha',
                  subCategoryList: []
                },
                {
                  name: 'Anyscale',
                  subCategoryList: []
                },
                {
                  name: 'Aviary',
                  subCategoryList: []
                },
                {
                  name: 'Banana',
                  subCategoryList: []
                },
                {
                  name: 'Baseten',
                  subCategoryList: []
                },
                {
                  name: 'Setup',
                  subCategoryList: []
                },
                {
                  name: 'Single model call',
                  subCategoryList: []
                },
                {
                  name: 'Chained model calls',
                  subCategoryList: []
                },
                {
                  name: 'Beam',
                  subCategoryList: []
                },
                {
                  name: 'CerebriumAI',
                  subCategoryList: []
                },
                {
                  name: 'Cohere',
                  subCategoryList: []
                },
                {
                  name: 'C Transformers',
                  subCategoryList: []
                },
                {
                  name: 'Databricks',
                  subCategoryList: []
                },
                {
                  name: 'DeepInfra',
                  subCategoryList: []
                },
                {
                  name: 'ForefrontAI',
                  subCategoryList: []
                },
                {
                  name: 'GooseAI',
                  subCategoryList: []
                },
                {
                  name: 'Jsonformer',
                  subCategoryList: []
                },
                {
                  name: 'Manifest',
                  subCategoryList: []
                },
                {
                  name: 'Modal',
                  subCategoryList: []
                },
                {
                  name: 'MosaicML',
                  subCategoryList: []
                },
                {
                  name: 'NLP Cloud',
                  subCategoryList: []
                },
                {
                  name: 'OpenAI',
                  subCategoryList: []
                },
                {
                  name: 'OpenLM',
                  subCategoryList: []
                },
                {
                  name: 'Petals',
                  subCategoryList: []
                },
                {
                  name: 'PipelineAI',
                  subCategoryList: []
                },
                {
                  name: 'Prediction Guard',
                  subCategoryList: []
                },
                {
                  name: 'Control the output structure/ type of LLMs',
                  subCategoryList: []
                },
                {
                  name: 'Chaining',
                  subCategoryList: []
                },
                {
                  name: 'PromptLayer OpenAI',
                  subCategoryList: []
                },
                {
                  name: 'ReLLM',
                  subCategoryList: []
                },
                {
                  name: 'Replicate',
                  subCategoryList: []
                },
                {
                  name: 'Runhouse',
                  subCategoryList: []
                },
                {
                  name: 'StochasticAI',
                  subCategoryList: []
                },
                {
                  name: 'Writer',
                  subCategoryList: []
                },
              ],
              img: require('../../assets/images/ownmodal/4/2.png')
            },
            {
              name: "Experimental",
              subCategory: [
                {
                  name: 'AI21',
                  subCategoryList: []
                },
                {
                  name: 'Aleph Alpha',
                  subCategoryList: []
                },
                {
                  name: 'Anyscale',
                  subCategoryList: []
                },
                {
                  name: 'Aviary',
                  subCategoryList: []
                },
                {
                  name: 'Banana',
                  subCategoryList: []
                },
                {
                  name: 'Baseten',
                  subCategoryList: []
                },
                {
                  name: 'Setup',
                  subCategoryList: []
                },
                {
                  name: 'Single model call',
                  subCategoryList: []
                },
                {
                  name: 'Chained model calls',
                  subCategoryList: []
                },
                {
                  name: 'Beam',
                  subCategoryList: []
                },
              ],
              img: require('../../assets/images/ownmodal/4/3.png')
            },
            {
              name: "Hubs",
              subCategory: [
                {
                  name: 'HuggingFace',
                  subCategoryList: []
                },
                {
                  name: 'Model Garden',
                  subCategoryList: []
                },
              ],
              img: require('../../assets/images/ownmodal/4/4.png')
            },
            {
              name: "Cloud Providers",
              subCategory: [
                {
                  name: 'Azure OpenAI',
                  subCategoryList: [
                    "GPT-3",
                    "Codex",
                    "DALL-E",
                    "ChatGPT",
                    "GPT-4"
                  ]
                },
                {
                  name: 'Bedrock',
                  subCategoryList: [
                    "Jurassic-2 by A121labs",
                    "Claude by Anthrop",
                    "Stable Diffusion by stability.ai",
                    "Amazon Titan by Amazon",
                  ]
                },
                {
                  name: 'Google Cloud Platform Vertex AI PaLM',
                  subCategoryList: [
                    " PaLM API for text",
                    "PaLM API for chat",
                    "Text embedding API",
                    "Codex APIs"
                  ]
                },
                {
                  name: 'SageMaker Endpoint',
                  subCategoryList: ['SageMaker Endpoint']
                },
              ],
              img: require('../../assets/images/ownmodal/4/5.png')
            },
          ]
        },
        {
          name: 'Chat',
          subCategory: [
            {
              name: "Anthropic",
              subCategory: []
            },
            {
              name: "Azure",
              subCategory: []
            },
            {
              name: "Google Vertex AI PaLM",
              subCategory: []
            },
            {
              name: "OpenAI",
              subCategory: []
            },
            {
              name: "PromptLayer ChatOpenAI",
              subCategory: []
            },
          ]
        },
        {
          name: 'Embeddings',
          subCategory: [
            {
              name: "Aleph Alpha",
              subCategory: []
            },
            {
              name: "Amazon Bedrock",
              subCategory: []
            },
            {
              name: "Azure OpenAI",
              subCategory: []
            },
            {
              name: "Cohere",
              subCategory: []
            },
            {
              name: "DashScope",
              subCategory: []
            },
            {
              name: "DeepInfra",
              subCategory: []
            },
            {
              name: "Elasticsearch",
              subCategory: []
            },
            {
              name: "Embaas",
              subCategory: []
            },
            {
              name: "Fake Embeddings",
              subCategory: []
            },
            {
              name: "Google Vertex AI PaLM",
              subCategory: []
            },
            {
              name: "Hugging Face Hub",
              subCategory: []
            },
            {
              name: "HuggingFace Instruct",
              subCategory: []
            },
            {
              name: "Jina",
              subCategory: []
            },
            {
              name: "Llama-cpp",
              subCategory: []
            },
            {
              name: "MiniMax",
              subCategory: []
            },
            {
              name: "ModelScope",
              subCategory: []
            },
            {
              name: "MosaicML",
              subCategory: []
            },
            {
              name: "OpenAI",
              subCategory: []
            },
            {
              name: "SageMaker Endpoint",
              subCategory: []
            },
            {
              name: "Self Hosted Embeddings",
              subCategory: []
            },
            {
              name: "Sentence Transformers",
              subCategory: []
            },
            {
              name: "Tensorflow Hub",
              subCategory: []
            },
          ]
        },
        {
          name: 'Bring your own model',
        },
      ],
      mandatory: true,
      questionType: "Foundational Model"
    },
  ],
  [
    { set: 3 },
    {
      id: 6,
      question: 'Select the data source',
      options: [
        {
          blImage: require('../../assets/images/ownmodal/5/AshColor/1.png'),
          clrImage: require('../../assets/images/ownmodal/5/BlueColor/1.png'),
          name: 'Database',
          subQuestions: ["Select the Data Source", "Enter the URL", "Enter Your Username", "Enter Your Password"]
        },
        {
          blImage: require('../../assets/images/ownmodal/5/AshColor/2.png'),
          clrImage: require('../../assets/images/ownmodal/5/BlueColor/2.png'),
          name: 'API'
        },
        {
          blImage: require('../../assets/images/ownmodal/5/AshColor/3.png'),
          clrImage: require('../../assets/images/ownmodal/5/BlueColor/3.png'),
          name: 'File System'
        },
        {
          blImage: require('../../assets/images/ownmodal/5/AshColor/4.png'),
          clrImage: require('../../assets/images/ownmodal/5/BlueColor/4.png'),
          name: 'SharePoint'
        },
        {
          blImage: require('../../assets/images/ownmodal/5/AshColor/5.png'),
          clrImage: require('../../assets/images/ownmodal/5/BlueColor/5.png'),
          name: 'Tabular data'
        },
      ],
      mandatory: true,
      questionType: "Data Source"
    },
    {
      id: 7,
      question: 'Select the Vector store(s) you would like for prompt engineering',
      mainCategory: [
        {
          name: 'OpenSource',
          subCategory: [
            {
              name: "Chroma"
            },
            {
              name: "FAISS"
            },
            {
              name: "AWADB"
            },
            {
              name: "Annoy"
            },
            {
              name: "Atlas"
            },
            {
              name: "Holorges"
            },
            {
              name: "DeepLake"
            },
            {
              name: "DocArrayHnswSearch"
            },
            {
              name: "DocArrayHnswInMemorySearch"
            },
            {
              name: "ElasticSearch"
            },
            {
              name: "LanceDB"
            },
            {
              name: "MatchingEngineDB"
            },
            {
              name: "MyScale"
            },
            {
              name: "OpenSearch"
            },
            {
              name: "PGVector"
            },
            {
              name: "SKLearnVectorStore"
            },
            {
              name: "Tair"
            },
            {
              name: "Typesense"
            },
            {
              name: "Weaviate"
            }
          ]
        },
        {
          name: "Private/SaaS",
          subCategory: [
            {
              name: "Azure Cognitive Search",
            },
            {
              name: "Clickhouse Vector Search",
            },
            {
              name: "Redis",
            },
            {
              name: "AnalyticDB",
            },
            {
              name: "Pinecone",
            },
            {
              name: "Qdrant",
            },
            {
              name: "SingleStoreDB vector search",
            },
            {
              name: "Supabase(Postgres)",
            },
            {
              name: "Tigris",
            },
            {
              name: "Vectara",
            },
            {
              name: "Zilliz",
            },
          ]
        }
      ],
      mandatory: true,
      questionType: "Data Vector Store"

    }
  ],
  [
    { set: 4 },
    {
      id: 8,
      question: 'Can we provide expert guidance to operate and manage Generative AI?',
      value: [
        'Yes',
        "No",
        'Maybe later'
      ],
      activeImageUrl: require('../../assets/images/ownmodal/Radio/active.png'),
      inActiveImageUrl: require('../../assets/images/ownmodal/Radio/inactive.png'),
      mandatory: true,
      questionType: "Generative AI"
    },
    {
      id: 9,
      question: 'Do you want to enable caching?',
      value: [
        'Yes',
        "No",
        'Maybe later'
      ],
      activeImageUrl: require('../../assets/images/ownmodal/Radio/active.png'),
      inActiveImageUrl: require('../../assets/images/ownmodal/Radio/inactive.png'),
      mandatory: true,
      questionType: "Caching"
    },
    {
      id: 10,
      question: 'Do you want to customize prompt?',
      value: [
        'Yes',
        "No",
        'Maybe later'
      ],
      activeImageUrl: require('../../assets/images/ownmodal/Radio/active.png'),
      inActiveImageUrl: require('../../assets/images/ownmodal/Radio/inactive.png'),
      mandatory: true,
      questionType: "Customize Prompt"
    },
    {
      id: 11,
      question: 'Where do you want to store your data?',
      options: [
        {
          blImage: require('../../assets/images/ownmodal/3/AskColor/1.png'),
          clrImage: require('../../assets/images/ownmodal/3/BlueColor/1.png'),
          name: 'AWS'
        },
        {
          blImage: require('../../assets/images/ownmodal/3/AskColor/3.png'),
          clrImage: require('../../assets/images/ownmodal/3/BlueColor/3.png'),
          name: 'Azure Blob'
        },
        {
          blImage: require('../../assets/images/ownmodal/3/AskColor/4.png'),
          clrImage: require('../../assets/images/ownmodal/3/BlueColor/4.png'),
          name: 'Cloud Storage'
        },
        {
          blImage: require('../../assets/images/ownmodal/6/AshColor/1.png'),
          clrImage: require('../../assets/images/ownmodal/6/BlueColor/1.png'),
          name: 'Big Query'
        },
        {
          blImage: require('../../assets/images/ownmodal/6/AshColor/2.png'),
          clrImage: require('../../assets/images/ownmodal/6/BlueColor/2.png'),
          name: 'Others'
        },
      ],
      mandatory: true,
      questionType: "Store Data"
    },
  ]
]
const OwnModal = () => {
  const [dataIndex, setDataIndex] = useState(0);
  const [clicking, setClicking] = useState(false);
  // const [forFocus, forSetFocus] = useState(null);

  useEffect(() => {
    setClicking(false)
  }, [clicking])

  const pagination = (direction, result) => {
    setClicking(true);
    setDataIndex(((prevIndex) => {
      if (direction === "next") {
        if (result) {
          if (prevIndex !== data.length - 1) {
            return prevIndex + 1
          }
        }

      } else {
        if (prevIndex !== 0) {
          return prevIndex - 1
        }
      }

      return prevIndex;
    }))
  }
  const paginationationInttializer = (index, result) => {
    setDataIndex((prevIndex) => {
      if (index > prevIndex && index === prevIndex + 1) {
        if (index === 0) {
          forSetFocus(result)
        }
        if (result) {
          return index
        }
      }
      if (index < prevIndex) {
        return index
      }

      return prevIndex
    })
  }
  const intialData = useMemo(() => {
    return data[dataIndex];
  })
  let pageidentifier = '';
  if (dataIndex === data.length - 1) {
    pageidentifier = 'last'
  } else if (dataIndex === 0) {
    pageidentifier = 'first'
  }
  return (
    <OwnModalContextProvider>
      <div className={classes.ownModalWrapper}>
        <OwnModalHeader data={intialData} setPagination={pagination} pageNoter={pageidentifier} />
        <OwnMOdalWrapper data={intialData} totalData={data} pageInializer={paginationationInttializer} pageNoter={pageidentifier} />
      </div>
    </OwnModalContextProvider>
  )
}

export default React.memo(OwnModal);