import { useSelector } from "react-redux";

import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import "app.scss";
import "appMediaQuery.scss";
// routing
import Routes from "routes";

// defaultTheme
import themes from "themes";

// project imports
import NavigationScroll from "layout/NavigationScroll";
import { Route } from "react-router";
import PageNotFound from "pi-screen/page_not_found";
import ReactGA from "react-ga4";
import React, { useEffect } from "react";
import Canvas from "views/canvas";
// ==============================|| APP ||============================== //
ReactGA.initialize("G-WTR3BS80J7");

const App = () => {
 
  const customization = useSelector((state) => state.customization);
  const theme = useSelector((state) => state.PiThemeReducer.theme);
  return (
    <div className={`app_container position-relative ${theme}`}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={themes(customization)}>
          <CssBaseline />
          <NavigationScroll>
            {/* <Routes></Routes> */}
            <Canvas/>
          </NavigationScroll>
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  );
};

export default App;
