import { lazy } from "react";

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/loading/Loadable";
import HomeComponent from "../pi-screen/homeComponent";
import Register from "pi-screen/register";
import HomeLayout from "pi-layout/homeLayout";
import Login from "pi-screen/login";

// chatflows routing
const Chatflows = Loadable(lazy(() => import("views/chatflows")));

// marketplaces routing
const Marketplaces = Loadable(lazy(() => import("views/marketplaces")));

// apikey routing
const APIKey = Loadable(lazy(() => import("views/apikey")));

// apikey routing
const Tools = Loadable(lazy(() => import("views/tools")));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: <HomeLayout />,
  children: [
    {
      path: "/",
      element: <HomeComponent />,
    },
    {
      path: "/Register",
      element: <Register />,
    },
    {
      path: "/Login",
      element: <Login />,
    },
    // {
    //   path: "/usecases",
    //   element: <APIKey />,
    // },

  ],

  // path: '/',
  // element: <MainLayout />,
  // children: [
  //     {
  //         path: '/',
  //         element: <Chatflows />
  //     },
  //     {
  //         path: '/chatflows',
  //         element: <Chatflows />
  //     },
  //     {
  //         path: '/marketplaces',
  //         element: <Marketplaces />
  //     },
  //     {
  //         path: '/apikey',
  //         element: <APIKey />
  //     },
  //     {
  //         path: '/tools',
  //         element: <Tools />
  //     }
  // ]
};

export default MainRoutes;
