import { lazy } from "react";

// project imports
import Loadable from "ui-component/loading/Loadable";
import MinimalLayout from "layout/MinimalLayout";
import UseCaseLayout from "pi-layout/useCaseLayout";
import UseCases from "pi-screen/usecases";
import Invite from "pi-screen/invite";
import Billing from "pi-screen/billing";
import PageNotFound from "pi-screen/page_not_found";
import OwnModal from "pi-screen/own_modal";
import TextToImagePdf from "pi-screen/usecases/simple_usecases/text_to_image_pdf";
import TextToVideo from "pi-screen/usecases/simple_usecases/text_to_video";
import QADocs from "pi-screen/usecases/simple_usecases/qa_docs";
import Summarization from "pi-screen/usecases/simple_usecases/summarization";
import Video from "pi-screen/usecases/simple_usecases/video";
import UseCaseGlobalCardScreen from "pi-screen/usecases/simple_usecases/usecase_global_cards";

// canvas routing
const Canvas = Loadable(lazy(() => import("views/canvas")));
const MarketplaceCanvas = Loadable(
  lazy(() => import("views/marketplaces/MarketplaceCanvas"))
);
const Chatflows = Loadable(lazy(() => import("views/chatflows")));
const Marketplaces = Loadable(lazy(() => import("views/marketplaces")));
// ==============================|| CANVAS ROUTING ||============================== //

const CanvasRoutes = {
  path: "/",
  element: <UseCaseLayout />,
  children: [
    {
      path: "usecases",
      element: <UseCases />,
    },
    {
      path: "usecases/Text_To_Image",
      element: <TextToImagePdf />,
    },
    {
      path: "usecases/Text_To_Video",
      element: <TextToVideo />,
    },
    {
      path: "usecases/qadocs",
      element: <QADocs />,
    },
    {
      path: "usecases/summarization",
      element: <Summarization />,
    },
    {
      path: "usecases/video",
      element: <Video />,
    },
  
    {
      path: "uiDriven",
      element: <Canvas />,
    },
    {
      path: "enterprises",
      element: <Marketplaces />,
    },
    {
      path: "ownModel",
      element: <OwnModal />,
    },
    {
      path: "/marketplace/:id",
      element: <MarketplaceCanvas />,
    },
    {
      path: "canvas",
      element: <Chatflows />,
    },
  ],
  // path: '/',
  // element: <MinimalLayout />,
  // children: [
  //     {
  //         path: '/canvas',
  //         element: <Canvas />
  //     },
  //     {
  //         path: '/canvas/:id',
  //         element: <Canvas />
  //     },
  //     {
  //         path: '/marketplace/:id',
  //         element: <MarketplaceCanvas />
  //     }
  // ]
};

export default CanvasRoutes;
